import React, { Component } from "react";
import {
  Label,
  Input,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Collapse,
  NavLink,
} from "reactstrap";
import axios from "axios";
import moment from "moment";
import { now } from "mongoose";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

class Story extends Component {
  constructor() {
    super();
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      responsive: {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5,
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3,
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
        },
      },
    };

    this.responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };
  componentDidMount() {
    let daysTill = moment(Date.now());
    let dayMarried = moment("2024-05-04 11:06:55");
    let diffToWedding = dayMarried.diff(daysTill, "days");

    let dayYes = moment("2023-05-28 11:06:55");
    let diffToYes = dayYes.diff(daysTill, "days");

    let daysSinceLove = moment("2019-05-04 11:06:55");
    let diffLove = daysSinceLove.diff(daysTill, "days");

    window.addEventListener("resize", this.updateDimensions);

    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
      diffToWedding: diffToWedding,
      diffToYes: diffToYes,
      diffLove: diffLove,
      glitch: "9",
    });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  goToPage(e) {
    if (e.target.innerHTML === "Home") {
      window.scrollTo(0, 0);
      this.setState({
        isOpen: !this.state.isOpen,
      });
      this.props.history.push("/");
    }

    if (e.target.innerHTML === "Our Story") {
      window.scrollTo(0, 0);
      this.setState({
        isOpen: !this.state.isOpen,
      });
      this.props.history.push("/story");
    }
    if (e.target.innerHTML === "Celebration") {
      window.scrollTo(0, 0);
      this.setState({
        isOpen: !this.state.isOpen,
      });
      this.props.history.push("/celebration");
    }

    if (e.target.innerHTML === "Photo Gallery") {
      window.scrollTo(0, 0);
      this.setState({
        isOpen: !this.state.isOpen,
      });
      this.props.history.push("/photo");
    }

    if (e.target.innerHTML === "RSVP") {
      window.scrollTo(0, 0);
      this.setState({
        isOpen: !this.state.isOpen,
      });
      this.props.history.push("/rsvp");
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.width <= 480 && (
          <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top smallNav">
            <button
              onClick={this.toggle}
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarTogglerDemo03"
              aria-controls="navbarTogglerDemo03"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <a class="navbar-brand" href="#">
              <img
                src={require("./ring.svg")}
                width="30px"
                height="30px"
                className=""
              />
            </a>

            <a class="navbar-brand" href="#">
              C&J
            </a>
            {this.state.isOpen && (
              <div class=" navbar-collapse" id="navbarTogglerDemo03">
                <ul class="navbar-nav mr-auto mt-2 mt-lg-0 cursiveFont">
                  <li class="nav-item active">
                    <button
                      type="button"
                      class="btn btn-link"
                      onClick={(e) => this.goToPage(e)}
                    >
                      Home
                    </button>
                  </li>
                  <li class="nav-item">
                    <button
                      type="button"
                      class="btn btn-link"
                      onClick={(e) => this.goToPage(e)}
                    >
                      Our Story
                    </button>
                  </li>
                  <li class="nav-item">
                    <button
                      type="button"
                      class="btn btn-link"
                      onClick={(e) => this.goToPage(e)}
                    >
                      Celebration
                    </button>{" "}
                    <sup style={{ color: "rgba(11, 11, 110)" }}> ✸</sup>
                  </li>
                  <li class="nav-item">
                    <button
                      type="button"
                      class="btn btn-link"
                      onClick={(e) => this.goToPage(e)}
                    >
                      Photo Gallery
                    </button>
                  </li>
                  <li class="nav-item">
                    <button
                      type="button"
                      class="btn btn-link"
                      onClick={(e) => this.goToPage(e)}
                    >
                      RSVP
                    </button>
                  </li>
                </ul>
                <form class="form-inline my-2 my-lg-0"></form>
              </div>
            )}
          </nav>
        )}

        <div className="container-fluid mainContainer">
          {this.state.width <= 480 && (
            <Carousel responsive={this.state.responsive}>
              <div className="text-center">
                <img
                  src={require("./firstLove.png")}
                  style={{ width: "100%", boxShadow: "0 4px 2px -2px gray" }}
                  className="ourStoryPhotosMobile"
                />
                <p style={{ marginBottom: "0px" }} className="straightFont">
                  The First Day
                </p>

                <p
                  style={{ marginBottom: "0px" }}
                  className="straightFont text-center"
                >
                  {" "}
                  5.4.2019
                </p>
              </div>
              <div className="text-center">
                <img
                  src={require("./theYes.png")}
                  className="ourStoryPhotosMobile"
                  style={{
                    boxShadow: "0 4px 2px -2px gray",
                    width: "100%",
                    height: "100%",
                    marginTop: "75px",
                  }}
                />
                <p style={{ marginBottom: "0px" }} className="straightFont">
                  The Yes Day
                </p>

                <p
                  style={{ marginBottom: "0px" }}
                  className="straightFont text-center"
                >
                  5.28.2023
                </p>
              </div>
              <div className="text-center">
                <img
                  src={require("./thebestDay.png")}
                  className="ourStoryPhotosMobile"
                  style={{
                    boxShadow: "0 4px 2px -2px gray",
                    width: "100%",
                    height: "100%",
                    marginTop: "75px",
                  }}
                />
                <p style={{ marginBottom: "0px" }} className="straightFont">
                  The Best Day
                </p>

                <p
                  style={{ marginBottom: "0px" }}
                  className="text-center straightFont"
                >
                  {" "}
                  5.4.2024
                </p>
              </div>
            </Carousel>
          )}
          {this.state.width > 480 && (
            <div className="row text-center">
              <div className="col-sm-12 ringTop">
                {" "}
                <img
                  src={require("./ring.svg")}
                  width="40px"
                  height="40px"
                  className=""
                />
              </div>
            </div>
          )}
          <div
            className="row text-center cursiveFont"
            style={{ marginTop: "10px" }}
          >
            {this.state.width <= 480 && (
              <div className="col-sm-12" style={{ paddingBottom: "10px" }}>
                <img
                  src={require("../../pinkflowersRev5.svg")}
                  width="300px"
                  height="100px"
                  className=""
                />
              </div>
            )}
            <div className="col-sm-12">
              <h1>Chris and Jenna</h1>
            </div>
          </div>
          <div class="paperOverlay"></div>
          <div className="row text-center topPush cursiveFont pageHeaders">
            <div className="col-sm-12">
              <h3>Our Story</h3>
            </div>
            <div className="col-sm-12 ">
              <img
                src={require("./heartbook.svg")}
                width="40px"
                height="40px"
                className=""
              />
            </div>
          </div>
          {this.state.width > 480 && (
            <div className="row text-center  justify-content-center cursiveFont">
              <div className="col-2">
                <button
                  type="button"
                  class="btn btn-link"
                  onClick={(e) => this.goToPage(e)}
                >
                  Home
                </button>
              </div>
              <div className="col-2">
                {" "}
                <button
                  type="button"
                  class="btn btn-link"
                  onClick={(e) => this.goToPage(e)}
                >
                  Our Story
                </button>
              </div>
              <div className="col-2">
                <button
                  type="button"
                  class="btn btn-link"
                  onClick={(e) => this.goToPage(e)}
                >
                  Celebration
                </button>{" "}
                <sup style={{ color: "rgba(11, 11, 110)" }}> ✸</sup>
              </div>
              <div className="col-2">
                <button
                  type="button"
                  class="btn btn-link"
                  onClick={(e) => this.goToPage(e)}
                >
                  Photo Gallery
                </button>
              </div>
              <div className="col-2">
                <button
                  type="button"
                  class="btn btn-link"
                  onClick={(e) => this.goToPage(e)}
                >
                  RSVP
                </button>
              </div>
            </div>
          )}
          {this.state.width > 480 && (
            <div
              class="row justify-content-center largeImageRow straightFont"
              style={{ fontWeight: "bold" }}
            >
              <div className="col-4 text-center">
                <img
                  style={{ boxShadow: "0 4px 2px -2px gray" }}
                  src={require("./firstLove.png")}
                  className="ourStoryPhotos"
                />
                <p style={{ marginBottom: "0px" }} className="straightFont">
                  The First Day
                </p>

                <p
                  style={{ marginBottom: "0px" }}
                  className="straightFont text-center"
                >
                  {" "}
                  5.4.2019
                </p>
              </div>
              <div className="col-4 text-center">
                <img
                  src={require("./theYes.png")}
                  style={{ boxShadow: "0 4px 2px -2px gray" }}
                  className="ourStoryPhotos"
                />
                <p style={{ marginBottom: "0px" }} className="straightFont">
                  The Yes Day
                </p>

                <p
                  style={{ marginBottom: "0px" }}
                  className="text-center straightFont"
                >
                  5.28.2023
                </p>
              </div>
              <div className="col-4 text-center">
                <img
                  style={{ boxShadow: "0 4px 2px -2px gray" }}
                  src={require("./thebestDay.png")}
                  className="ourStoryPhotos"
                />
                <p style={{ marginBottom: "0px" }}>The Best Day</p>

                <p style={{ marginBottom: "0px" }} className="text-center">
                  {" "}
                  5.4.2024
                </p>
              </div>
            </div>
          )}

          <div
            className="row yearBlockPadding straightFont"
            style={{ marginTop: "100px" }}
          >
            <div className=" sideYearText">2017</div>
            <div className="col-10">
              <span className="workBreak">
                Jenna’s sister, Katie, got engaged to Chris’s childhood friend,
                Christian, in January 2017. A few months later, they hosted a
                pool party at Christian’s mom’s house in Nicholson, PA to
                celebrate their engagement and introduce the wedding party to
                each other. Prior to the party, Katie described Chris to Jenna
                as “a tall guy with a beard, and he will probably be wearing an
                Iron Maiden shirt” (not much has changed!). Although that was
                the first day they met, Chris and Jenna continued to be in the
                same friend circle for many years thereafter.
              </span>
            </div>
          </div>

          <div className="row yearBlockPadding straightFont">
            <div className=" sideYearText">2019</div>
            <div className="col-10">
              <span className="workBreak">
                Freshly 21-year-old Jenna was excited for a night of drinking
                and celebrating on the town with her friends from home. Chris
                was newly single, and Jenna shared with her girlfriends that she
                thought he was cute but didn’t want to rush into anything. Well,
                “girl code” was broken that day, and word got back to Chris…
                <br />
                <br />
                That night, at River Grille, Chris bought Jenna a rum bucket to
                kick off the celebration. Once they started talking, they
                couldn’t stop. It was like the whole world around them didn’t
                exist – it was just the two of them, great conversation, and
                maybe one too many Bud Lights.
                <br />
                <br />
                Later in April, they got together to celebrate the engagement of
                Chris’s childhood best friend, Jareth, and his (now wife) Karah.
                Again, even though the bar was packed that night, it only felt
                like the two of them existed in the world.
                <br />
                <br />
                On May 4th, Chris took a trip down to see Jenna at college in
                Kutztown, PA. They walked around the quaint town and stopped at
                The Tavern for dinner and drinks. During that conversation,
                Chris asked Jenna to be his girlfriend. The two were beyond
                giddy and excited for this next adventure. A few weeks later, on
                Memorial Day weekend, Chris and Jenna shared their first “I love
                you” at their home.
              </span>
            </div>
          </div>

          <div className="row yearBlockPadding straightFont">
            <div className="sideYearText">2020</div>
            <div className="col-10">
              <span className="workBreak">
                Jenna was finishing her senior year of college when COVID began.
                What started as an extension to her spring break turned out to
                be a multi-month lockdown. Jenna quarantined with Chris during
                this time. It was evident then that living with and loving each
                other was as effortless as breathing.
                <br />
                <br />
                Jenna moved to State College, PA to begin her graduate work in
                Chemistry. Chris and Jenna were long distance for an additional
                2 and a half years as she completed her Master’s degree. They
                drove to see each other 1-2 times a month, talked on the phone
                for hours, and had video chat dates to stay connected. While
                long distance was a trying time, it made it that much more
                rewarding when they finally were able to merge their lives in
                2022.
              </span>
            </div>
          </div>

          <div className="row yearBlockPadding straightFont">
            <div className=" sideYearText">2022</div>
            <div className="col-10">
              <span className="workBreak">
                After defending in November, Jenna and Cooper (her foster fail)
                moved in with Chris, Tibbs, and Lou – finally, everyone was
                under one roof at last. Since then, they worked on numerous
                house projects, explored new areas, picked up new hobbies, and
                just enjoyed being together.
              </span>
            </div>
          </div>

          <div
            className="row yearBlockPadding straightFont"
            style={{ marginBottom: "70px" }}
          >
            <div className="sideYearText">2023</div>
            <div className="col-10">
              <span className="workBreak">
                On May 27th, Chris and Jenna took a trip to the Kutztown area to
                celebrate his aunt and uncle’s 50th wedding anniversary. Since
                it is a special place to them, they decided to stay overnight
                and plan a date day on May 28th. It began with a walk around
                campus, where they eventually ended up at Jenna’s favorite spot
                – the Kutztown water tower. With a picturesque backdrop of
                rolling mountains and endless blue skies behind them, Chris got
                down on one knee and asked Jenna to spend the rest of their
                lives together.
              </span>
            </div>
          </div>
        </div>

        <footer class="footer">
          <div class="container">
            <span class="text-muted cursiveFont bolding hashTag">
              #OnceUponAMilheim
            </span>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default Story;
