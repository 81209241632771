import React, { Component } from "react";
import {
  Label,
  Input,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Collapse,
  NavLink,
} from "reactstrap";
import axios from "axios";
import moment from "moment";
import { now } from "mongoose";

class Gallery extends Component {
  constructor() {
    super();
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      showAccordian: "collapseOne",
      width: window.innerWidth,
      height: window.innerHeight,
      imgArr: [
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
        53,
        54,
        55,
        56,
        57,
        58,
        59,
        60,
        61,
        62,
        63,
        64,
        65,
        66,
      ],
      arrOfText: [
        `April 2019 Our very first selfie together`,
        `May 2019 First Knoebels date`,
        `May 2019 First Knoebels date`,
        `May 2019 REU at Penn State`,
        `June 2019 First trip to the arboretum`,
        `July 2019 Modeling Jenna's heels`,
        `July 2019 First 4th of July`,
        `Aug 2019 Baseball date
        `,
        `Aug 2019
        Video chat date`,
        `August 2019
        Sunflower date`,
        `September 2019
        Labor Day`,
        `October 2019
        Rickaby’s wedding
        `,
        `December 2019
        Olivia’s first birthday
        `,
        `December 2019
        New Year’s Eve`,
        `January 2020
        Chris’s birthday
        `,
        `February 2020
        Valentine’s Day`,
        `April 2020
        So covid begins…
        `,
        `May 2020
        Jenna’s backyard graduation`,
        `June 2020
        First fishing trip`,
        `June 2020
        Photoshoot`,
        `July 2020 Painting the house
        `,
        `August 2020
        Kanyak anniversary photoshoot`,
        `September 2020
        Jenna’s apartment in party mode`,
        `October 2020
        Covid pumpkin patch`,
        `October 2020
        Halloween`,
        `December 2020
        Covid Christmas`,
        `January 2021
        Kanyak Christmas gift`,
        `February 2021
        Valentine’s Day`,
        `April 2021
        Isaac's first Easter`,
        `May 2021
        2 year anniversary`,
        `May 2021
        2nd anniversary`,
        `June 2021
        Lock on Simpson bridge`,
        `July 2021
        Cape May
        `,
        `October 2021
        Pumpkin patch`,
        `November 2021
        Christmas card picture`,
        `December 2021
        Christmas`,
        `December 2021
        New Year’s Eve murder mystery party`,
        `January 2022
        Fishing
        `,
        `March 2022
        Isaac's first birthday`,
        `March 2022
        Jenna's birthday`,
        `April 2022
        Chris assembling the smoker`,
        `May 2022
        Casino ready`,
        `June 2022
        Father’s Day`,
        `July 2022
        Date night`,
        `July 2022
        First stand up show`,
        `July 2022
        Eagle rock family day`,
        `September 2022
        OCMD`,
        `September 2022
        Friends wedding`,
        `October 2022
        Friend's wedding
        `,
        `October 2022
        Iron Maiden`,
        `December 2022
        Christmas`,
        `January 2023
        Chris’s birthday`,
        `April 2023
        Free Beer and Hot Wings`,
        `March 2023
        Jenna's birthday`,
        `April 2023
        Longwood Gardens`,
        `May 2023
        Stephen's Anniversary Party`,
        `May 28, 2023
        Engagement day`,
        `May 28, 2023
        Engagement day`,
        `May 28, 2023
        Engagement day`,
        `May 28, 2023
        Engagement day`,
        `May 28, 2023
        Engagement day`,
        `May 28, 2023
        Engagement day`,
        `May 28, 2023
        Engagement day`,
        `May 28, 2023
        Engagement day`,
        `Sir Cooper, the Playful. Earl of the toys`,
        `Master Lou(boo), the Hungry. Duke Of the food `,
        `Lord Tibbz, the Attitude. Ruler of the realm.`,
      ],
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };
  componentDidMount() {
    let daysTill = moment(Date.now());
    let dayMarried = moment("2024-05-04 11:06:55");
    let diffToWedding = dayMarried.diff(daysTill, "days");

    let dayYes = moment("2023-05-28 11:06:55");
    let diffToYes = dayYes.diff(daysTill, "days");

    let daysSinceLove = moment("2019-05-04 11:06:55");
    let diffLove = daysSinceLove.diff(daysTill, "days");

    window.addEventListener("resize", this.updateDimensions);

    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
      diffToWedding: diffToWedding,
      diffToYes: diffToYes,
      diffLove: diffLove,
      glitch: "9",
    });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  goToPage(e) {
    if (e.target.innerHTML === "Home") {
      window.scrollTo(0, 0);
      this.setState({
        isOpen: !this.state.isOpen,
      });
      this.props.history.push("/");
    }

    if (e.target.innerHTML === "Our Story") {
      window.scrollTo(0, 0);
      this.setState({
        isOpen: !this.state.isOpen,
      });
      this.props.history.push("/story");
    }
    if (e.target.innerHTML === "Celebration") {
      window.scrollTo(0, 0);
      this.setState({
        isOpen: !this.state.isOpen,
      });
      this.props.history.push("/celebration");
    }

    if (e.target.innerHTML === "Photo Gallery") {
      window.scrollTo(0, 0);
      this.setState({
        isOpen: !this.state.isOpen,
      });
      this.props.history.push("/photo");
    }

    if (e.target.innerHTML === "RSVP") {
      window.scrollTo(0, 0);
      this.setState({
        isOpen: !this.state.isOpen,
      });
      this.props.history.push("/rsvp");
    }
  }

  handleAccordion(e) {
    this.setState({ showAccordian: e.target.id });
  }

  render() {
    return (
      <React.Fragment>
        {this.state.width <= 480 && (
          <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top smallNav">
            <button
              onClick={this.toggle}
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarTogglerDemo03"
              aria-controls="navbarTogglerDemo03"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <a class="navbar-brand" href="#">
              <img
                src={require("./ring.svg")}
                width="30px"
                height="30px"
                className=""
              />
            </a>

            <a class="navbar-brand" href="#">
              C&J
            </a>
            {this.state.isOpen && (
              <div class=" navbar-collapse" id="navbarTogglerDemo03">
                <ul class="navbar-nav mr-auto mt-2 mt-lg-0 cursiveFont">
                  <li class="nav-item active">
                    <button
                      type="button"
                      class="btn btn-link"
                      onClick={(e) => this.goToPage(e)}
                    >
                      Home
                    </button>
                  </li>
                  <li class="nav-item">
                    <button
                      type="button"
                      class="btn btn-link"
                      onClick={(e) => this.goToPage(e)}
                    >
                      Our Story
                    </button>
                  </li>
                  <li class="nav-item">
                    <button
                      type="button"
                      class="btn btn-link"
                      onClick={(e) => this.goToPage(e)}
                    >
                      Celebration
                    </button>{" "}
                    <sup style={{ color: "rgba(11, 11, 110)" }}> ✸</sup>
                  </li>
                  <li class="nav-item">
                    <button
                      type="button"
                      class="btn btn-link"
                      onClick={(e) => this.goToPage(e)}
                    >
                      Photo Gallery
                    </button>
                  </li>
                  <li class="nav-item">
                    <button
                      type="button"
                      class="btn btn-link"
                      onClick={(e) => this.goToPage(e)}
                    >
                      RSVP
                    </button>
                  </li>
                </ul>
                <form class="form-inline my-2 my-lg-0"></form>
              </div>
            )}
          </nav>
        )}

        <div className="container-fluid mainContainer">
          {/* {this.state.width <= 480 && (
            <div class="row justify-content-center">
              <div>
                <img src={require("./mainPhoto.jpg")} className="mainPhoto" />
              </div>
            </div>
          )} */}
          {this.state.width > 480 && (
            <div className="row text-center">
              <div className="col-sm-12 ringTop">
                {" "}
                <img
                  src={require("./ring.svg")}
                  width="40px"
                  height="40px"
                  className=""
                />
              </div>
            </div>
          )}
          <div className="row text-center cursiveFont">
            {this.state.width <= 480 && (
              <div
                className="col-sm-12"
                style={{ paddingBottom: "10px", paddingTop: "80px" }}
              >
                <img
                  src={require("../../pinkflowersRev5.svg")}
                  width="300px"
                  height="100px"
                  className=""
                />
              </div>
            )}
            <div className="col-sm-12">
              <h1>Chris and Jenna</h1>
            </div>
          </div>
          <div class="paperOverlay"></div>
          <div className="row text-center topPush cursiveFont ">
            <div className="col-sm-12 pageHeaders">
              <h3>Photo Gallery</h3>
            </div>
            <div className="col-sm-12">
              <img
                src={require("./gallery.svg")}
                width="30px"
                height="30px"
                className=""
              />
            </div>
          </div>
          {this.state.width > 480 && (
            <div className="row text-center  justify-content-center cursiveFont">
              <div className="col-2">
                <button
                  type="button"
                  class="btn btn-link"
                  onClick={(e) => this.goToPage(e)}
                >
                  Home
                </button>
              </div>
              <div className="col-2">
                {" "}
                <button
                  type="button"
                  class="btn btn-link"
                  onClick={(e) => this.goToPage(e)}
                >
                  Our Story
                </button>
              </div>
              <div className="col-2">
                <button
                  type="button"
                  class="btn btn-link"
                  onClick={(e) => this.goToPage(e)}
                >
                  Celebration
                </button>{" "}
                <sup style={{ color: "rgba(11, 11, 110)" }}> ✸</sup>
              </div>
              <div className="col-2">
                <button
                  type="button"
                  class="btn btn-link"
                  onClick={(e) => this.goToPage(e)}
                >
                  Photo Gallery
                </button>
              </div>
              <div className="col-2">
                <button
                  type="button"
                  class="btn btn-link"
                  onClick={(e) => this.goToPage(e)}
                >
                  RSVP
                </button>
              </div>
            </div>
          )}
          {/* {this.state.width > 480 && (
            <div class="row justify-content-center largeImageRow">
              <div>
                <img src={require("./mainPhoto.jpg")} className="mainPhoto" />
              </div>
            </div>
          )} */}

          {this.state.width > 480 && (
            <div className="row" style={{ marginBottom: "150px" }}>
              {this.state.imgArr.map((number) => (
                <div>
                  {number !== 30 && (
                    <div>
                      {number % 2 === 0 ? (
                        <div
                          class="card cardMainBlue m-1"
                          style={{
                            width: "20rem",
                            boxShadow: "0 4px 2px -2px gray",
                          }}
                        >
                          <img
                            class="card-img-top imageCard"
                            src={require(`./imageGallery/${number}.jpg`)}
                            alt="Card image cap"
                          />
                          <div class="card-body">
                            <p class="card-text">
                              {this.state.arrOfText[number]}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div
                          class="card cardMainPink m-1"
                          style={{
                            width: "20rem",
                            boxShadow: "0 4px 2px -2px gray",
                          }}
                        >
                          <img
                            class="card-img-top imageCard"
                            src={require(`./imageGallery/${number}.jpg`)}
                            alt="Card image cap"
                          />
                          <div class="card-body">
                            <p class="card-text">
                              {this.state.arrOfText[number]}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}

          {this.state.width <= 480 && (
            <div className="row" style={{ marginBottom: "70px" }}>
              {this.state.imgArr.map((number) => (
                <div>
                  {number % 2 === 0 ? (
                    <div
                      class="card cardMainBlue m-1"
                      style={{ width: "100% -5px" }}
                    >
                      <img
                        class="card-img-top imageCard"
                        src={require(`./imageGallery/${number}.jpg`)}
                        alt="Card image cap"
                      />
                      <div class="card-body">
                        <p class="card-text straightFont">
                          {this.state.arrOfText[number]}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div
                      class="card cardMainPink m-1"
                      style={{ width: "100% -5px" }}
                    >
                      <img
                        class="card-img-top imageCard"
                        src={require(`./imageGallery/${number}.jpg`)}
                        alt="Card image cap"
                      />
                      <div class="card-body">
                        <p class="card-text straightFont">
                          {this.state.arrOfText[number]}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>

        <footer class="footer">
          <div class="container">
            <span class="text-muted cursiveFont bolding hashTag">
              #OnceUponAMilheim
            </span>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default Gallery;
