import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";

import { setCurrentUser, logoutUser } from "./actions/authActions";
import { Provider } from "react-redux";
import store from "./store";

// import Navbarr from "./components/layout/Navbar";

import Dashboard from "./components/dashboard/Dashboard";
import Story from "./components/dashboard/Story";
import Celebration from "./components/dashboard/Celebration";
import Gallery from "./components/dashboard/Gallery";
import RSVP from "./components/dashboard/RSVP";

import "./App.css";

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className="App">
            {/* <Navbarr /> */}
            <Route exact path="/" component={Dashboard} />
            <Route exact path="/story" component={Story} />
            <Route exact path="/celebration" component={Celebration} />
            <Route exact path="/photo" component={Gallery} />
            <Route exact path="/RSVP" component={RSVP} />
            {/* <Route exact path="/register" component={Register} />
            <Route exact path="/login" component={Login} />
            <Switch>
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
              <PrivateRoute path="/calanderView" component={CalanderView} />
              <PrivateRoute
                path="/rescheduledView"
                component={RescheduleDashboard}
              />
              <PrivateRoute
                path="/closedAppointments"
                component={ClosedDashboard}
              />
            </Switch> */}
          </div>
        </Router>
      </Provider>
    );
  }
}
export default App;
