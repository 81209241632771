import React, { Component } from "react";
import {
  Label,
  Input,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Collapse,
  NavLink,
} from "reactstrap";
import axios from "axios";
import moment from "moment";
import { now } from "mongoose";

class RSVP extends Component {
  constructor() {
    super();
    this.toggle = this.toggle.bind(this);
    this.state = {
      successSubmit: false,
      firstName: "",
      lastName: "",
      brunch: false,
      diet: false,
      restrictions: "",
      reception: false,
      drinkArr: [""],
      songArr: [""],
      errorLastName: false,
      errorFirstName: false,
      errorRestrict: false,
      genError: false,
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };
  componentDidMount() {
    // let daysTill = moment(Date.now());
    // let dayMarried = moment("2024-05-04 11:06:55");
    // let diffToWedding = dayMarried.diff(daysTill, "days");

    // let dayYes = moment("2023-05-28 11:06:55");
    // let diffToYes = dayYes.diff(daysTill, "days");

    // let daysSinceLove = moment("2019-05-04 11:06:55");
    // let diffLove = daysSinceLove.diff(daysTill, "days");

    window.addEventListener("resize", this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  goToPage(e) {
    if (e.target.innerHTML === "Home") {
      window.scrollTo(0, 0);
      this.setState({
        isOpen: !this.state.isOpen,
      });
      this.props.history.push("/");
    }

    if (e.target.innerHTML === "Our Story") {
      window.scrollTo(0, 0);
      this.setState({
        isOpen: !this.state.isOpen,
      });
      this.props.history.push("/story");
    }
    if (e.target.innerHTML === "Celebration") {
      window.scrollTo(0, 0);
      this.setState({
        isOpen: !this.state.isOpen,
      });
      this.props.history.push("/celebration");
    }

    if (e.target.innerHTML === "Photo Gallery") {
      window.scrollTo(0, 0);
      this.setState({
        isOpen: !this.state.isOpen,
      });
      this.props.history.push("/photo");
    }

    if (e.target.innerHTML === "RSVP") {
      window.scrollTo(0, 0);
      this.setState({
        isOpen: !this.state.isOpen,
      });
      this.props.history.push("/rsvp");
    }
  }

  handleAccordion(e) {
    this.setState({ showAccordian: e.target.id });
  }

  submitInfo = (index, appointmentId, imageDataUrl) => {
    let token = {};
    token.firstName = this.state.firstName.trim();
    token.lastName = this.state.lastName.trim();
    token.brunch = this.state.brunch;
    token.diet = this.state.diet;
    token.restrictions = this.state.restrictions;
    token.reception = this.state.reception;
    token.drinkArr = this.state.drinkArr;
    token.songArr = this.state.songArr;
    let error = false;
    if (this.state.firstName === "") {
      this.setState({ errorFirstName: true });
      error = true;
    }

    if (this.state.lastName === "") {
      this.setState({ errorLastName: true });
      error = true;
    }

    if (this.state.diet === true && this.state.restrictions === "") {
      this.setState({ errorRestrict: true });
      error = true;
    }

    if (error === false) {
      axios
        .post("/api/users/register", token, {})
        .then((res) => {
          this.clearInfoSubmit();
        })
        .catch((err) => {
          this.setState({ genError: true });
        });
    }
  };

  clearInfoSubmit() {
    this.setState({
      firstName: "",
      lastName: "",
      brunch: false,
      diet: false,
      restrictions: "",
      reception: false,
      drinkArr: [""],
      songArr: [""],
      successSubmit: true,
    });
  }

  clearInfo() {
    this.setState({
      firstName: "",
      lastName: "",
      brunch: false,
      diet: false,
      restrictions: "",
      reception: false,
      drinkArr: [""],
      songArr: [""],
    });
  }

  handleDrinkUpdate = (index, newValue) => {
    const updatedArray = [...this.state.drinkArr];

    updatedArray[index] = newValue;

    this.setState({ drinkArr: updatedArray });
  };

  handleDrinkDelete = (index) => {
    const updatedArray = [...this.state.drinkArr];

    updatedArray.splice(index, 1);

    this.setState({ drinkArr: updatedArray });
  };

  handleSongUpdate = (index, newValue) => {
    const updatedArray = [...this.state.songArr];

    updatedArray[index] = newValue;

    this.setState({ songArr: updatedArray });
  };

  handleSongDelete = (index) => {
    const updatedArray = [...this.state.songArr];

    updatedArray.splice(index, 1);

    this.setState({ songArr: updatedArray });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.width <= 480 && (
          <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top smallNav">
            <button
              onClick={this.toggle}
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarTogglerDemo03"
              aria-controls="navbarTogglerDemo03"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <a class="navbar-brand" href="#">
              <img
                src={require("./ring.svg")}
                width="30px"
                height="30px"
                className=""
              />
            </a>

            <a class="navbar-brand" href="#">
              C&J
            </a>
            {this.state.isOpen && (
              <div class=" navbar-collapse" id="navbarTogglerDemo03">
                <ul class="navbar-nav mr-auto mt-2 mt-lg-0 cursiveFont">
                  <li class="nav-item active">
                    <button
                      type="button"
                      class="btn btn-link"
                      onClick={(e) => this.goToPage(e)}
                    >
                      Home
                    </button>
                  </li>
                  <li class="nav-item">
                    <button
                      type="button"
                      class="btn btn-link"
                      onClick={(e) => this.goToPage(e)}
                    >
                      Our Story
                    </button>
                  </li>
                  <li class="nav-item">
                    <button
                      type="button"
                      class="btn btn-link"
                      onClick={(e) => this.goToPage(e)}
                    >
                      Celebration
                    </button>{" "}
                    <sup style={{ color: "rgba(11, 11, 110)" }}> ✸</sup>
                  </li>
                  <li class="nav-item">
                    <button
                      type="button"
                      class="btn btn-link"
                      onClick={(e) => this.goToPage(e)}
                    >
                      Photo Gallery
                    </button>
                  </li>
                  <li class="nav-item">
                    <button
                      type="button"
                      class="btn btn-link"
                      onClick={(e) => this.goToPage(e)}
                    >
                      RSVP
                    </button>
                  </li>
                </ul>
                <form class="form-inline my-2 my-lg-0"></form>
              </div>
            )}
          </nav>
        )}

        <div className="container-fluid mainContainer">
          {this.state.width <= 480 && (
            <div class="row justify-content-center">
              <div>
                <img
                  src={require("./imageGallery/beach.jpg")}
                  className="mainPhoto"
                  style={{
                    height: "300px",
                    marginTop: "50px",
                    boxShadow: "0 4px 2px -2px gray",
                  }}
                />
              </div>
            </div>
          )}
          {this.state.width > 480 && (
            <div className="row text-center">
              <div className="col-sm-12 ringTop">
                {" "}
                <img
                  src={require("./ring.svg")}
                  width="40px"
                  height="40px"
                  className=""
                />
              </div>
            </div>
          )}
          <div className="row text-center cursiveFont">
            {this.state.width <= 480 && (
              <div
                className="col-sm-12"
                style={{ paddingBottom: "10px", paddingTop: "20px" }}
              >
                <img
                  src={require("../../pinkflowersRev5.svg")}
                  width="300px"
                  height="100px"
                  className=""
                />
              </div>
            )}
            <div className="col-sm-12">
              <h1>Chris and Jenna</h1>
            </div>
          </div>
          <div class="paperOverlay"></div>
          <div className="row text-center topPush cursiveFont ">
            <div className="col-sm-12 pageHeaders">
              <h3>RSVP</h3>
            </div>
            <div className="col-sm-12">
              <img
                src={require("./rsvp.svg")}
                width="30px"
                height="30px"
                className=""
              />
            </div>
          </div>
          {this.state.width > 480 && (
            <div className="row text-center  justify-content-center cursiveFont">
              <div className="col-2">
                <button
                  type="button"
                  class="btn btn-link"
                  onClick={(e) => this.goToPage(e)}
                >
                  Home
                </button>
              </div>
              <div className="col-2">
                {" "}
                <button
                  type="button"
                  class="btn btn-link"
                  onClick={(e) => this.goToPage(e)}
                >
                  Our Story
                </button>
              </div>
              <div className="col-2">
                <button
                  type="button"
                  class="btn btn-link"
                  onClick={(e) => this.goToPage(e)}
                >
                  Celebration
                </button>{" "}
                <sup style={{ color: "rgba(11, 11, 110)" }}> ✸</sup>
              </div>
              <div className="col-2">
                <button
                  type="button"
                  class="btn btn-link"
                  onClick={(e) => this.goToPage(e)}
                >
                  Photo Gallery
                </button>
              </div>
              <div className="col-2">
                <button
                  type="button"
                  class="btn btn-link"
                  onClick={(e) => this.goToPage(e)}
                >
                  RSVP
                </button>
              </div>
            </div>
          )}
          {this.state.width > 480 && (
            <div class="row justify-content-center largeImageRow">
              <div>
                <img
                  style={{ boxShadow: "0 4px 2px -2px gray" }}
                  src={require("./imageGallery/beach.jpg")}
                  className="mainPhoto"
                />
              </div>
            </div>
          )}

          {this.state.successSubmit === false ? (
            <div
              onClick={(e) => this.setState({ genError: false })}
              className={` ${
                this.state.width <= 480
                  ? "centerMobile straightFont"
                  : "center straightFont"
              } `}
              style={{ boxShadow: "12px 12px 2px 1px rgba(11, 11, 110)" }}
            >
              {this.state.genError === true && (
                <span style={{ color: "red" }}>
                  Sorry! Something went wrong! Please try again or contact Chris
                  or Jenna
                </span>
              )}
              <h5 className="text-center straightFont">RSVP Here!</h5>
              <div className="col-sm-12 text-center">
                <img
                  src={require("../../bluePlant22.svg")}
                  width="150px"
                  height="50px"
                  className=""
                />
              </div>
              <span
                className="text-center"
                style={{ fontSize: "9px", textAlign: "center" }}
              >
                **Please fill out individually even if you are part of a couple.
                Thank you!
              </span>
              <div className="row ">
                <div class="form-group ">
                  <label
                    for="firstName"
                    style={
                      this.state.errorFirstName === true
                        ? { color: "red" }
                        : { color: "black" }
                    }
                  >
                    *First Name:
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter First Name"
                    id="firstName"
                    onChange={(e) =>
                      this.setState({ firstName: e.target.value })
                    }
                    onClick={(e) => this.setState({ errorFirstName: false })}
                    value={this.state.firstName}
                  />
                </div>
                <div class="form-group">
                  <label
                    for="lastname"
                    style={
                      this.state.errorLastName === true
                        ? { color: "red" }
                        : { color: "black" }
                    }
                  >
                    *Last Name:
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Last Name"
                    id="lastname"
                    onChange={(e) =>
                      this.setState({ lastName: e.target.value })
                    }
                    onClick={(e) => this.setState({ errorLastName: false })}
                    value={this.state.lastName}
                  />
                </div>
              </div>
              <div className="row ">
                <div class="form-group form-check">
                  <label class="form-check-label">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      onChange={(e) =>
                        this.setState({ reception: e.target.checked })
                      }
                    />{" "}
                    Check if you will be joining us for the{" "}
                    <strong>ceremony and reception</strong> on 5/4/2024.
                  </label>
                </div>
              </div>
              <div className="row ">
                <div class="form-group form-check">
                  <label class="form-check-label">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      onChange={(e) =>
                        this.setState({ brunch: e.target.checked })
                      }
                    />{" "}
                    Check if you will be joining us for the celebratory{" "}
                    <strong>brunch</strong> on 5/5/2024.
                  </label>
                </div>
              </div>
              <div className="row ">
                <div class="form-group form-check">
                  <label class="form-check-label">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      onChange={(e) =>
                        this.setState({ diet: e.target.checked })
                      }
                    />{" "}
                    Check if you have any dietary restrictions.
                  </label>
                </div>
              </div>
              {this.state.diet === true && (
                <div className="row ">
                  <div
                    class="form-group"
                    style={{
                      width: "100%",
                    }}
                  >
                    <label
                      for="diet"
                      style={
                        this.state.errorRestrict === true
                          ? { color: "red" }
                          : { color: "black" }
                      }
                    >
                      *List diet restrictions:
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Diet Restrictions"
                      id="diet"
                      onChange={(e) =>
                        this.setState({ restrictions: e.target.value })
                      }
                      onClick={(e) => this.setState({ errorRestrict: false })}
                      value={this.state.restrictions}
                    />
                  </div>
                </div>
              )}
              {this.state.drinkArr.map((text, number) => (
                <div className="row ">
                  <div
                    class="form-group"
                    style={{
                      width: "100%",
                    }}
                  >
                    {number === 0 && (
                      <label for="drinks">
                        {" "}
                        Please list a few of your favorite alcoholic drinks:
                      </label>
                    )}
                    <div class="input-group mb-1">
                      <input
                        type="text"
                        class="form-control"
                        placeholder={`Enter Drink #${Number(number) + 1}`}
                        aria-label="Enter Drink"
                        aria-describedby="basic-addon2"
                        id="drinks"
                        onChange={(e) =>
                          this.handleDrinkUpdate(number, e.target.value)
                        }
                        value={this.state.drinkArr[number]}
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-primary"
                          type="button"
                          onClick={(e) =>
                            this.setState({
                              drinkArr: [...this.state.drinkArr, ""],
                            })
                          }
                        >
                          +
                        </button>
                      </div>
                      {this.state.drinkArr.length > 1 && (
                        <div class="input-group-append">
                          <button
                            class="btn btn-secondary"
                            type="button"
                            onClick={(e) => this.handleDrinkDelete(number)}
                          >
                            -
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}

              {this.state.songArr.map((text, number) => (
                <div className="row ">
                  <div
                    class="form-group"
                    style={{
                      width: "100%",
                    }}
                  >
                    {number === 0 && (
                      <label for="drinks">
                        {" "}
                        Please enter any song suggestions:
                      </label>
                    )}
                    <div class="input-group mb-1">
                      <input
                        type="text"
                        class="form-control"
                        placeholder={`Enter Song #${Number(number) + 1}`}
                        aria-label="Enter Song"
                        aria-describedby="basic-addon2"
                        id="drinks"
                        onChange={(e) =>
                          this.handleSongUpdate(number, e.target.value)
                        }
                        value={this.state.songArr[number]}
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-primary"
                          type="button"
                          onClick={(e) =>
                            this.setState({
                              songArr: [...this.state.songArr, ""],
                            })
                          }
                        >
                          +
                        </button>
                      </div>
                      {this.state.songArr.length > 1 && (
                        <div class="input-group-append">
                          <button
                            class="btn btn-secondary"
                            type="button"
                            onClick={(e) => this.handleSongDelete(number)}
                          >
                            -
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}

              <div className="row ">
                <button
                  type="submit"
                  class="btn btn-primary"
                  style={{
                    width: "100%",
                    backgroundColor: "rgba(11, 11, 110)!important",
                  }}
                  onClick={(e) => this.submitInfo(e)}
                >
                  Submit
                </button>
              </div>
              <div className="row ">
                <button
                  type="submit"
                  class="btn btn-secondary"
                  style={{
                    width: "100%",
                    backgroundColor: "rgba(11, 11, 110)!important",
                    marginTop: "10px",
                  }}
                  onClick={(e) => this.clearInfo(e)}
                >
                  Clear
                </button>
              </div>
            </div>
          ) : (
            <div className="centerSuccess">
              <h1 style={{ color: "rgba(85, 133, 96)", textAlign: "center" }}>
                Success!
              </h1>
              <h5 style={{ color: "rgb(189, 126, 130)", textAlign: "center" }}>
                Thank You!
              </h5>
            </div>
          )}
        </div>
        <div style={{ marginBottom: "100px" }}></div>
        <footer class="footer">
          <div class="container">
            <span class="text-muted cursiveFont bolding hashTag">
              #OnceUponAMilheim
            </span>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default RSVP;
