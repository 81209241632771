import React, { Component } from "react";
import {
  Label,
  Input,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Collapse,
  NavLink,
} from "reactstrap";
import axios from "axios";
import moment from "moment";
import { now } from "mongoose";

class Dashboard extends Component {
  constructor() {
    super();
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };
  componentDidMount() {
    let daysTill = moment(Date.now());
    let dayMarried = moment("2024-05-05");
    let diffToWedding = dayMarried.diff(daysTill, "days");

    let dayYes = moment("2023-05-28");
    let diffToYes = dayYes.diff(daysTill, "days");

    let daysSinceLove = moment("2019-05-04 11:06:55");
    let diffLove = daysSinceLove.diff(daysTill, "days");

    window.addEventListener("resize", this.updateDimensions);

    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
      diffToWedding: diffToWedding,
      diffToYes: diffToYes,
      diffLove: diffLove,
      glitch: "9",
    });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  goToPage(e) {
    if (e.target.innerHTML === "Home") {
      window.scrollTo(0, 0);
      this.setState({
        isOpen: !this.state.isOpen,
      });
      this.props.history.push("/");
    }

    if (e.target.innerHTML === "Our Story") {
      window.scrollTo(0, 0);
      this.setState({
        isOpen: !this.state.isOpen,
      });
      this.props.history.push("/story");
    }
    if (e.target.innerHTML === "Celebration") {
      window.scrollTo(0, 0);
      this.setState({
        isOpen: !this.state.isOpen,
      });
      this.props.history.push("/celebration");
    }

    if (e.target.innerHTML === "Photo Gallery") {
      window.scrollTo(0, 0);
      this.setState({
        isOpen: !this.state.isOpen,
      });
      this.props.history.push("/photo");
    }

    if (e.target.innerHTML === "RSVP") {
      window.scrollTo(0, 0);
      this.setState({
        isOpen: !this.state.isOpen,
      });
      this.props.history.push("/rsvp");
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.width <= 480 && (
          <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top smallNav">
            <button
              onClick={this.toggle}
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarTogglerDemo03"
              aria-controls="navbarTogglerDemo03"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <a class="navbar-brand" href="#">
              <img
                src={require("./ring.svg")}
                width="30px"
                height="30px"
                className=""
              />
            </a>

            <a class="navbar-brand" href="#">
              C&J
            </a>
            {this.state.isOpen && (
              <div class=" navbar-collapse" id="navbarTogglerDemo03">
                <ul class="navbar-nav mr-auto mt-2 mt-lg-0 cursiveFont">
                  <li class="nav-item active">
                    <button
                      type="button"
                      class="btn btn-link"
                      onClick={(e) => this.goToPage(e)}
                    >
                      Home
                    </button>
                  </li>
                  <li class="nav-item">
                    <button
                      type="button"
                      class="btn btn-link"
                      onClick={(e) => this.goToPage(e)}
                    >
                      Our Story
                    </button>
                  </li>
                  <li class="nav-item">
                    <button
                      type="button"
                      class="btn btn-link"
                      onClick={(e) => this.goToPage(e)}
                    >
                      Celebration
                    </button>{" "}
                    <sup style={{ color: "rgba(11, 11, 110)" }}> ✸</sup>
                  </li>
                  <li class="nav-item">
                    <button
                      type="button"
                      class="btn btn-link"
                      onClick={(e) => this.goToPage(e)}
                    >
                      Photo Gallery
                    </button>
                  </li>
                  <li class="nav-item">
                    <button
                      type="button"
                      class="btn btn-link"
                      onClick={(e) => this.goToPage(e)}
                    >
                      RSVP
                    </button>
                  </li>
                </ul>
                <form class="form-inline my-2 my-lg-0"></form>
              </div>
            )}
          </nav>
        )}

        <div className="container-fluid mainContainer">
          {this.state.width <= 480 && (
            <div class="row justify-content-center">
              <div>
                <img
                  style={{ boxShadow: "0 4px 2px -2px gray" }}
                  src={require("./imageGallery/21.jpg")}
                  className="mainPhoto"
                />
              </div>
            </div>
          )}
          {this.state.width > 480 && (
            <div className="row text-center">
              <div className="col-sm-12 ringTop">
                {" "}
                <img
                  src={require("./ring.svg")}
                  width="40px"
                  height="40px"
                  className=""
                />
              </div>
            </div>
          )}
          <div
            className="row text-center cursiveFont"
            style={{ marginTop: "20px" }}
          >
            {this.state.width <= 480 && (
              <div className="col-sm-12" style={{ paddingBottom: "10px" }}>
                <img
                  src={require("../../pinkflowersRev5.svg")}
                  width="300px"
                  height="100px"
                  className=""
                />
                <div className="text-left straightFont">
                  <p>
                    {" "}
                    <sup style={{ color: "rgba(11, 11, 110)" }}>✸</sup>
                    <span style={{ color: "rgba(11, 11, 110)" }}>
                      NEW 3/3/2024:{" "}
                    </span>
                    As we get closer to the big day, we will be updating the
                    website periodically. Highlights of the updates will be
                    posted here!{" "}
                  </p>
                  <p>
                    <sup style={{ color: "rgba(11, 11, 110)" }}>✸</sup>
                    <span style={{ color: "rgba(11, 11, 110)" }}>
                      NEW 3/3/2024:
                    </span>{" "}
                    Check out the updated details under Celebration - Timeline!
                    Friday night there will be a Rehearsal + Pizza Party and
                    more information was added to Saturday. A new tab, AirBNB,
                    was also added to the Celebration section.
                  </p>
                  <p>
                    <sup style={{ color: "rgb(218, 152, 156)" }}>✸</sup>
                    <span style={{ color: "rgb(218, 152, 156)" }}>
                      NEW 4/3/2024:
                    </span>{" "}
                    30 Days Out! Check out the latest updates
                  </p>{" "}
                </div>
              </div>
            )}
            <div className="col-sm-12">
              <h1>Chris and Jenna</h1>
            </div>
          </div>
          <div class="paperOverlay"></div>
          <div className="row text-center topPush cursiveFont ">
            <div className="col-sm-12 pageHeaders">
              <h1>Welcome</h1>
            </div>
            <div className="col-sm-12">
              <h2>to our forever</h2>
            </div>
            {this.state.width <= 480 && (
              <div className="col-sm-12">
                <img
                  src={require("../../bluePlant22.svg")}
                  width="300px"
                  height="100px"
                  className=""
                />
              </div>
            )}
          </div>
          {this.state.width > 480 && (
            <div className="row text-center  justify-content-center cursiveFont">
              <div className="text-left straightFont">
                <p>
                  {" "}
                  <sup style={{ color: "rgba(11, 11, 110)" }}>✸</sup>
                  <span style={{ color: "rgba(11, 11, 110)" }}>
                    NEW 3/3/2024:{" "}
                  </span>
                  As we get closer to the big day, we will be updating the
                  website periodically. Highlights of the updates will be posted
                  here!{" "}
                </p>
                <p>
                  <sup style={{ color: "rgba(11, 11, 110)" }}>✸</sup>
                  <span style={{ color: "rgba(11, 11, 110)" }}>
                    NEW 3/3/2024:
                  </span>{" "}
                  Check out the updated details under Celebration - Timeline!
                  Friday night there will be a Rehearsal + Pizza Party and more
                  information was added to Saturday. A new tab, AirBNB, was also
                  added to the Celebration section.
                </p>
                <p>
                  <sup style={{ color: "rgb(218, 152, 156)" }}>✸</sup>
                  <span style={{ color: "rgb(218, 152, 156)" }}>
                    NEW 4/3/2024:
                  </span>{" "}
                  30 Days Out! Check out the latest updates
                </p>{" "}
              </div>
              <div className="col-2">
                <button
                  type="button"
                  class="btn btn-link"
                  onClick={(e) => this.goToPage(e)}
                >
                  Home
                </button>
              </div>
              <div className="col-2">
                {" "}
                <button
                  type="button"
                  class="btn btn-link"
                  onClick={(e) => this.goToPage(e)}
                >
                  Our Story
                </button>
              </div>
              <div className="col-2">
                <button
                  type="button"
                  class="btn btn-link"
                  onClick={(e) => this.goToPage(e)}
                >
                  Celebration
                </button>
                <sup style={{ color: "rgba(11, 11, 110)" }}> ✸</sup>
              </div>
              <div className="col-2">
                <button
                  type="button"
                  class="btn btn-link"
                  onClick={(e) => this.goToPage(e)}
                >
                  Photo Gallery
                </button>
              </div>
              <div className="col-2">
                <button
                  type="button"
                  class="btn btn-link"
                  onClick={(e) => this.goToPage(e)}
                >
                  RSVP
                </button>
              </div>
            </div>
          )}
          {this.state.width > 480 && (
            <div class="row justify-content-center largeImageRow">
              <div>
                <img
                  style={{ boxShadow: "0 4px 2px -2px gray" }}
                  src={require("./imageGallery/21.jpg")}
                  className="mainPhoto"
                />
              </div>
            </div>
          )}
          <div class="row justify-content-center cursiveFont">
            {String(this.state.diffToWedding)
              .split("")
              .map((number) => (
                <div class="col-auto blueSquare">{number}</div>
              ))}
            {/* <div class="col-auto blueSquare">4</div>
            <div class="col-auto blueSquare"> 0</div> */}
          </div>
          <div className="row text-center counterPush">
            <div className="col-sm-12 cursiveFont">
              <h3>Days until "I do"</h3>
              <h7>May 4th, 2024</h7>
            </div>
          </div>
          <div class="row justify-content-center cursiveFont counterSpace">
            {String(this.state.diffToYes)
              .split("")
              .map((number) => (
                <div class="col-auto blueSquare">
                  {number === "-" ? 0 : number}
                </div>
              ))}
            {/* <div class="col-auto blueSquare">4</div>
            <div class="col-auto blueSquare"> 0</div> */}
          </div>
          <div className="row text-center counterPush">
            <div className="col-sm-12 cursiveFont">
              <h3>Days since she said "yes"</h3>
              <h7>May 28th, 2023</h7>
            </div>
          </div>
          <div class="row justify-content-center cursiveFont counterSpace">
            {String(this.state.diffLove)
              .split("")
              .map((number) => (
                <div>
                  {number !== "-" && (
                    <div class="col-auto blueSquare">
                      {number === "-" ? 0 : number}
                    </div>
                  )}
                </div>
              ))}
            {/* <div class="col-auto blueSquare">4</div>
            <div class="col-auto blueSquare"> 0</div> */}
          </div>
          <div className="row text-center counterPush">
            <div className="col-sm-12 cursiveFont">
              <h3>Days since our love story began</h3>
              <h7>May 4th, 2019</h7>
            </div>
          </div>
          <div class="row justify-content-center cursiveFont counterSpace">
            {String(this.state.glitch)
              .split("")
              .map((number) => (
                <div>
                  {number !== "-" && (
                    <div class="col-auto blueSquare">
                      {number === "-" ? 0 : number}
                    </div>
                  )}
                </div>
              ))}
            <div class="col-auto blueSquare"> 9</div>
            <div class="col-auto blueSquare">9</div>
            <div class="col-auto blueSquare">9̴̧͓̎͛̅⁻⁹²⁹³</div>
            <div
              class=" blueSquare"
              style={{
                backgroundColor: "rgba(0, 0, 128)",
                color: "white",
                marginLeft: "1px",
              }}
            >
              ¹̵̠̀̾²̶̼̐́¹̶̪̓͝²̶̳̀ͅ
            </div>
            <div
              class=" "
              style={{
                backgroundColor: "rgba(0, 0, 128)",
                color: "white",
                marginLeft: "1px",
              }}
            >
              ⁻⁹²⁹³ W̵̡̌̋́H̵̰̙̝͊̾Ò̴̥̩̓ ̵͙͌̑S̵͈̽U̵̗̣̍̈́M̶̗͙͋̋M̴̭̗̔͋O̸̻͑̍͗N̴͈̠͛̉S̶̡̘̲͂ ᵘⁿᵈᵉᶠᶦⁿᵉᵈᴺᵃᴺ⁹̴̮͎̽̚²̶͍͗̑⁹̷͚͐̓³̷̻̑ᴺᵃᴺ
            </div>
            <br />

            <div
              class=""
              style={{
                backgroundColor: "rgba(0, 0, 128)",
                color: "white",
              }}
            >
              9̵͈͕̼̜͉͈͉̘̓͜͝9̶͍̼̭̉̇̉́̇̚9̸͎̈ T̸̢̨͍̙̘͖̣̪̠̣͉͆̽̄͛̎́͗̉̍̕H̸̨̨̧̦̣̟̝͔̳͈͖͕̙̺͔̖̖̞̟̞̲̬̜͚̦̖͚̜̤̮͗̆͂̾͜É̵̗͍̭̺͚̰͈͍͋̈́̋̽͛̅͐͆̚ F̵̢̨̧̧̧̨̻̲̤̮͈͎̤̞̻̲̘͇̤͉̦̙͛̔͂͌̒̉͘̕Ơ̸̹̮̪̫̦͗̑̿̒̂̓͋̄̉͆̀͂̅̅̆̎̀̿̋̒̇̌̽̂̌͛̄̀͛̚̕͘͝ͅR̸̡̡̟̙̮͚̪̜̖͍̞͍̭̮̬̞͉͔̣̗͇̗̬̞͖͔̋͋̆͐̀̐͂̍̽̌͐͆̃̎̈́̀̐̆̾̉̈́̀̒͐͌̌̈́̾̀̍̽̄͊́͘͘̕͜͠ͅB̴̛̹̮̟̬̱͙̖̥͇͉͎̪̤͇͔̳̪̞̻̟̪̖͚͖̦͒̔̾̌̃̔̊͐̿͆̽͑̍̈́͝Ȉ̶̢̛͖͊̓͊̿̋̒̏̽̈̏̈̔̀̈́̉̒̀͑͒̓͘̚͠͝D̷̡̢̛̛͓̤͓̩͍̟͖͕͉̺̓̄͊̀̓̎͂͋̿̓͗̎̒̋̅̓̐͂͋͑͂̅̂̒͊͛̇̈́̕͝͝͠D̸̳̳͇̓̏̎̊E̸̢̨̡̨̲̘͔̙͔͉͇̞̹͙͍̟͆͂̓̏̉̉̕N̵̨̥̩̗̭̺̱̙̬͔̱͓̱̘̩̼̂̿̃̑̑̂͋̉̕ ̷̛̬͎̝̯̰͇͈̫͂̋̽̌͌̋̽̀̃̏̈̍̿̀͋͐̽̅͋̅̉̇͐̓̎̏̈́͑̚͘͘͝͝͝͝
            </div>
            {/* <div
              class=""
              style={{
                backgroundColor: "rgba(0, 0, 128, 0.638)",
                color: "red",
              }}
            >
              ᵁⁿᶜᵃᵘᵍʰᵗ ᴿᵃⁿᵍᵉᴱʳʳᵒʳ
            </div> */}
            <div
              class=""
              style={{
                backgroundColor: "rgba(0, 0, 128)",
                color: "white",
              }}
            >
              ̱̘̩̼ ̷̨̛̬͎̝̯̰͇͈̫͎̤͂̋̽̌͌̋̽̀̃̏̈̍̿̀͋͐̽̅͋̅̉̇͐̓̎̏̈́͑̚͘͘͝͝͝͝Ņ̴̨̧̨̱̮̥̦̤̘̘͇͈̹̟̼̳̙͙̦̼̦̮̟̰̗͒̓͒̅̄̉̂̈́̂̍͌͑̽̀͋̇́̋̈͊͆̓̾̎̄̈̀̎͘͘̚͝͝Ṳ̸̖̼̝̒̍̉͆̇̊͆͐̅̑̏̈́̑͑͂̅̋͊̌́̀̆̕͠͠͠͝͝M̴̡̧̧̢̢̛̙̳̪̳͇͈͉͖͉̗̣̣͈̳͍̯͎̣̺͉̜̱̳̯̰̣̼̣͖̲̳̹̻͙͍͗̍͒̇̋̐͌̏̈́͊̽̏̄͑̒͑̾̆̅̌̃̌̓̈́̿̒̉͗̂͛̂̍̓̀̉̀́̚̕̕͜͜͠͝͝ͅB̸̨̢̨̨̧̧̧̻̲̼̹͇̣̼̤̘̬̼͔͉̻̲̖͋̔̐̑̈́̽̆͛̊̓̀̉̽̆̿̓̃̀̇́̏̑́͒̅̍̓͐͘̚͝͝͠ͅE̷̡̨̡͚̮̘̠̩̙̣͍̣͇̝͍͕̯̜͉̥̙̞͖̙̞̠̻̿͌͂͐͜R̷̡̢̨̧̻͎̙̹̗͉̞̠̣̦̱̖̺͈̟̀̽̔͒̆͑̚͜͠
            </div>
            {/* <div
              class=""
              style={{
                backgroundColor: "rgba(0, 0, 128, 0.638)",
                color: "red",
              }}
            >
              ᴿᵉᶠᵉʳᵉⁿᶜᵉᴱʳʳᵒʳ: ᶜ̵̡̧̨̡̢̡̡̫̺̪͓͓̺͈̳̥̳̳̱̳͈͉̰̬͈̲̜̠̟̩̲̖͈̱͈̦̩̠͓͖͇́̏̎̾̊̓́͆͐̔͆͐̈́̀̆̂͋̑̒͋̂͛͊͝͝ᵁ̵̧̛̛̜̟̝̠̱̫̘̤̘̩̝̯̊̽̓̑͆͋̈́̽́́̃͛͒͐̉̉͐́̔͛͛̌̾̽̽̌̕ᴿ̸̧̨̡̩̻̮̗̹͓̜̜̭͇̲̹̬̰̖̫̳̘͙̘̫̬̮̟̻̙͙̾́̐́̂͂̅̀͑̏̏̃̄̓͐̃͆̋͐̓͆͊̈̔̎͜ͅˢ̶̢̧̛̛͈͖͕̮̜̬͍͐͛̎̀̐̅̒̈́̏̈̍̈̐̉̓̈́̀̉̀̓͒͒̌̎̂̕͝͠ᴱ̴̨̧̨̛̩̜̲̭͉̯͇̳͙̦͈̝̫̥̪̱͚̱̩̣̯͉̪̥̗͔̤̩̬̏̑̈́̄̈́̾͑͐̒̾͘͝ͅͅͅᴰ̶̡̛̤̘͉͉̘͉̼͕̞̼̫͙͍͓̭̫̟̹̱̬̝͕̹͎̼̮͉̿͋̆̂̀̃͛͒̂̎̂̓͌̐͊̅̈̋̚͜͜ ᶦˢ ⁿᵒᵗ ᵈᵉᶠᶦⁿᵉᵈ
            </div> */}
            {/* <div class="col-auto blueSquare">4</div>
            <div class="col-auto blueSquare"> 0</div> */}
          </div>
          <div className="row text-center counterPush">
            <div className="col-sm-12 cursiveFont">
              <h4>Total number of times asking for a ring</h4>
              <h8>May 4th, 2020</h8>
            </div>
          </div>
          <span className="disclaimer straightFont">
            *This number is displayed exactly as intended.* This number is
            larger than the number of atoms in the observable universe (Love you
            dear).{" "}
          </span>
        </div>

        <footer class="footer">
          <div class="container">
            <span class="text-muted cursiveFont bolding hashTag">
              #OnceUponAMilheim
            </span>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default Dashboard;
