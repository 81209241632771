import React, { Component } from "react";
import {
  Label,
  Input,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Collapse,
  NavLink,
} from "reactstrap";
import axios from "axios";
import moment from "moment";
import { now } from "mongoose";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

class Celebration extends Component {
  constructor() {
    super();
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      showAccordian: "collapseOne",
      width: window.innerWidth,
      height: window.innerHeight,
      responsive: {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5,
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3,
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
        },
      },
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };
  componentDidMount() {
    let daysTill = moment(Date.now());
    let dayMarried = moment("2024-05-04 11:06:55");
    let diffToWedding = dayMarried.diff(daysTill, "days");

    let dayYes = moment("2023-05-28 11:06:55");
    let diffToYes = dayYes.diff(daysTill, "days");

    let daysSinceLove = moment("2019-05-04 11:06:55");
    let diffLove = daysSinceLove.diff(daysTill, "days");

    window.addEventListener("resize", this.updateDimensions);

    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
      diffToWedding: diffToWedding,
      diffToYes: diffToYes,
      diffLove: diffLove,
      glitch: "9",
    });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  goToPage(e) {
    if (e.target.innerHTML === "Home") {
      window.scrollTo(0, 0);
      this.setState({
        isOpen: !this.state.isOpen,
      });
      this.props.history.push("/");
    }

    if (e.target.innerHTML === "Our Story") {
      window.scrollTo(0, 0);
      this.setState({
        isOpen: !this.state.isOpen,
      });
      this.props.history.push("/story");
    }
    if (e.target.innerHTML === "Celebration") {
      window.scrollTo(0, 0);
      this.setState({
        isOpen: !this.state.isOpen,
      });
      this.props.history.push("/celebration");
    }

    if (e.target.innerHTML === "Photo Gallery") {
      window.scrollTo(0, 0);
      this.setState({
        isOpen: !this.state.isOpen,
      });
      this.props.history.push("/photo");
    }

    if (e.target.innerHTML === "RSVP") {
      window.scrollTo(0, 0);
      this.setState({
        isOpen: !this.state.isOpen,
      });
      this.props.history.push("/rsvp");
    }
  }

  handleAccordion(e) {
    this.setState({ showAccordian: e.target.id });
  }

  render() {
    return (
      <React.Fragment>
        {this.state.width <= 480 && (
          <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top smallNav">
            <button
              onClick={this.toggle}
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarTogglerDemo03"
              aria-controls="navbarTogglerDemo03"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <a class="navbar-brand" href="#">
              <img
                src={require("./ring.svg")}
                width="30px"
                height="30px"
                className=""
              />
            </a>

            <a class="navbar-brand" href="#">
              C&J
            </a>
            {this.state.isOpen && (
              <div class=" navbar-collapse" id="navbarTogglerDemo03">
                <ul class="navbar-nav mr-auto mt-2 mt-lg-0 cursiveFont">
                  <li class="nav-item active">
                    <button
                      type="button"
                      class="btn btn-link"
                      onClick={(e) => this.goToPage(e)}
                    >
                      Home
                    </button>
                  </li>
                  <li class="nav-item">
                    <button
                      type="button"
                      class="btn btn-link"
                      onClick={(e) => this.goToPage(e)}
                    >
                      Our Story
                    </button>
                  </li>
                  <li class="nav-item">
                    <button
                      type="button"
                      class="btn btn-link"
                      onClick={(e) => this.goToPage(e)}
                    >
                      Celebration
                    </button>{" "}
                    <sup style={{ color: "rgba(11, 11, 110)" }}> ✸</sup>
                  </li>
                  <li class="nav-item">
                    <button
                      type="button"
                      class="btn btn-link"
                      onClick={(e) => this.goToPage(e)}
                    >
                      Photo Gallery
                    </button>
                  </li>
                  <li class="nav-item">
                    <button
                      type="button"
                      class="btn btn-link"
                      onClick={(e) => this.goToPage(e)}
                    >
                      RSVP
                    </button>
                  </li>
                </ul>
                <form class="form-inline my-2 my-lg-0"></form>
              </div>
            )}
          </nav>
        )}

        <div className="container-fluid mainContainer">
          {this.state.width <= 480 && (
            <div class="row justify-content-center">
              <div>
                <img
                  src={require("./imageGallery/60.jpg")}
                  className="mainPhoto"
                  style={{ height: "60vh", boxShadow: "0 4px 2px -2px gray" }}
                />
              </div>
            </div>
          )}
          {this.state.width > 480 && (
            <div className="row text-center">
              <div className="col-sm-12 ringTop">
                {" "}
                <img
                  src={require("./ring.svg")}
                  width="40px"
                  height="40px"
                  className=""
                />
              </div>
            </div>
          )}
          <div
            className="row text-center cursiveFont"
            style={{ marginTop: "40px" }}
          >
            {this.state.width <= 480 && (
              <div className="col-sm-12" style={{ paddingBottom: "10px" }}>
                <img
                  src={require("../../pinkflowersRev5.svg")}
                  width="300px"
                  height="100px"
                  className=""
                />
              </div>
            )}
            <div className="col-sm-12">
              <h1>Chris and Jenna</h1>
            </div>
          </div>
          <div class="paperOverlay"></div>
          <div className="row text-center topPush cursiveFont ">
            <div className="col-sm-12 pageHeaders">
              <h3>Celebration</h3>
            </div>

            <div className="col-sm-12">
              <img
                src={require("./bells.svg")}
                width="30px"
                height="30px"
                className=""
              />
            </div>
          </div>
          {this.state.width > 480 && (
            <div className="row text-center  justify-content-center cursiveFont">
              <div className="col-2">
                <button
                  type="button"
                  class="btn btn-link"
                  onClick={(e) => this.goToPage(e)}
                >
                  Home
                </button>
              </div>
              <div className="col-2">
                {" "}
                <button
                  type="button"
                  class="btn btn-link"
                  onClick={(e) => this.goToPage(e)}
                >
                  Our Story
                </button>
              </div>
              <div className="col-2">
                <button
                  type="button"
                  class="btn btn-link"
                  onClick={(e) => this.goToPage(e)}
                >
                  Celebration
                </button>{" "}
                <sup style={{ color: "rgba(11, 11, 110)" }}> ✸</sup>
              </div>
              <div className="col-2">
                <button
                  type="button"
                  class="btn btn-link"
                  onClick={(e) => this.goToPage(e)}
                >
                  Photo Gallery
                </button>
              </div>
              <div className="col-2">
                <button
                  type="button"
                  class="btn btn-link"
                  onClick={(e) => this.goToPage(e)}
                >
                  RSVP
                </button>
              </div>
            </div>
          )}
          {this.state.width > 480 && (
            <div class="row justify-content-center largeImageRow">
              <div>
                <img
                  style={{ boxShadow: "0 4px 2px -2px gray" }}
                  src={require("./imageGallery/celebration.png")}
                  className="mainPhoto"
                />
              </div>
            </div>
          )}

          <div id="accordion" style={{ marginBottom: "70px" }}>
            <div class="card">
              <div
                class="card-header"
                id="headingOne"
                style={{ boxShadow: "0 4px 2px -2px gray" }}
              >
                <h5
                  class="mb-0"
                  id="collapseOne"
                  onClick={(e) => this.handleAccordion(e)}
                >
                  <button
                    class="btn btn-link cursiveFont largeFontButton"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                    id="collapseOne"
                    onClick={(e) => this.handleAccordion(e)}
                  >
                    Micro Wedding Statement
                  </button>
                </h5>
              </div>

              <div
                class={
                  this.state.showAccordian === "collapseOne"
                    ? "collapse show"
                    : "collapse"
                }
                aria-labelledby="headingOne"
                data-parent="#accordion"
              >
                <div class="card-body straightFont">
                  As we have discussed what our “perfect” wedding day looks
                  like, we picture an ocean backdrop in a special place with a
                  handful of people closest to us.
                  <br /> <br />
                  We recognize and appreciate that we have quite a large group
                  of family and friends who would love to join us in this
                  celebration. Your love and support does not go unnoticed and
                  we are immensely grateful.
                  <br />
                  <br />
                  At this time, we have decided to have a small, intimate beach
                  wedding with just our parents, siblings, and closest friends
                  that are like siblings.
                  <br />
                  <br /> Thank you SO much in advance for your understanding. We
                  love everyone so much and greatly appreciate each and every
                  one of you!
                </div>
              </div>
            </div>

            <div class="card">
              <div
                class="card-header"
                id="headingTwo"
                style={{ boxShadow: "0 4px 2px -2px gray" }}
              >
                <h5
                  class="mb-0"
                  id="collapseTwo"
                  onClick={(e) => this.handleAccordion(e)}
                >
                  <button
                    class="btn btn-link collapsed cursiveFont largeFontButton"
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                    id="collapseTwo"
                    onClick={(e) => this.handleAccordion(e)}
                  >
                    Timeline
                  </button>{" "}
                  <sup style={{ color: "rgba(11, 11, 110)" }}> ✸</sup>
                  <sup style={{ color: "rgb(218, 152, 156)" }}> ✸</sup>{" "}
                </h5>
              </div>
              <div
                class={
                  this.state.showAccordian === "collapseTwo"
                    ? "collapse show"
                    : "collapse"
                }
                aria-labelledby="headingTwo"
                data-parent="#accordion"
                style={{ boxShadow: "0 4px 2px -2px gray" }}
              >
                <div className="card-body straightFont">
                  <div className="topTimeline">
                    {" "}
                    <div className="secondTimeline">
                      <h3 className="cursiveFont boldThedays">
                        Friday, May 3rd, 2024{" "}
                        <sup style={{ color: "rgba(11, 11, 110)" }}> ✸</sup>
                      </h3>
                      <h4 className="cursiveFont">
                        5:30pm - Rehearsal + Pizza Party (optional)
                      </h4>
                      <span>
                        We’d like to invite you to join us at the 59th Street
                        Dune to do a walk through of what to expect on Saturday.
                        We will meet at approximately 5:30pm at the entrance,
                        pictured below. 
                      </span>
                      {this.state.width > 480 ? (
                        <div className="">
                          <img
                            src={require("./dune.jpg")}
                            width="500px"
                            height="500px"
                            class
                            className="1"
                          />
                        </div>
                      ) : (
                        <Carousel responsive={this.state.responsive}>
                          <div className="">
                            <img
                              src={require("./dune.jpg")}
                              width="500px"
                              height="500px"
                              class
                              className="1"
                            />
                          </div>
                        </Carousel>
                      )}
                      <br />
                      <br />
                      <span>
                        There is a chance that a wedding will be happening at
                        this time. If so, please be respectful and do not
                        interrupt or interfere with their ceremony. Please come
                        with an open and flexible mindset in case we have to
                        walk a block up or down in the sand.
                      </span>
                      <br />
                      <br />
                      <span>
                        After we do a walk through, we’d love for you to join us
                        at our AirBNB for a pizza party! We will text out the
                        address that weekend. You’re welcome to follow us there
                        afterwards too.
                      </span>{" "}
                      <br />
                      <br />
                      <span>
                        This rehearsal is{" "}
                        <strong>
                          intended to be very informal and low key
                        </strong>
                        , so it is okay if you are unable to attend. Feel free
                        to wear casual attire and join us for some fun!
                      </span>
                    </div>{" "}
                  </div>{" "}
                  <br />
                  <br />
                  <div className="topTimeline">
                    {" "}
                    <div className="secondTimeline">
                      <h3 className="cursiveFont boldThedays">
                        Saturday, May 4th, 2024
                      </h3>
                      <div className="secondTimeline">
                        <h3 className="cursiveFont">
                          ~10:00am - Morning Meetup at AirBNB
                        </h3>

                        <span>
                          Anyone is welcome to hang out at the AirBNB anytime
                          before the ceremony on Saturday! We will text out the
                          address that weekend, but it is right across the
                          street from the venue on oceanside. Please see the
                          AirBNB tab for more details on our vision.
                        </span>
                      </div>{" "}
                    </div>{" "}
                  </div>
                  <br /> <br />
                  <div className="topTimeline">
                    <div className="secondTimeline">
                      <h4 className="cursiveFont">4:00pm – Ceremony Begins</h4>
                      <span>
                        Please do not arrive prior to 3:40pm. Please stay in the
                        dune walkway and respect our wedding coordinator’s
                        instructions.
                      </span>
                      <br />
                      <br />
                      <span>
                        Following the Ceremony, which will be about 15 minutes,
                        please remain on the beach and wait to be called for
                        formal pictures with the bride and groom.
                      </span>{" "}
                      <br />
                      <br />
                      <span>
                        Once the group formal pictures are complete, everyone
                        but Jenna, Chris, and photographer will go to the venue
                        and cocktail hour will begin. 
                      </span>
                      <br />
                      <br />
                      <span>
                        You are welcome to park at Coastal 59 and walk to the
                        beach, or park beachside and move your car following the
                        ceremony. Please see the Venue tab for a labeled map.
                      </span>
                    </div>{" "}
                  </div>
                  <br /> <br />
                  <div className="topTimeline">
                    <div className="secondTimeline">
                      <h3 className="cursiveFont">
                        4:30pm - 6:30pm – Dinner, Drinks, and Dances
                      </h3>
                      <span>
                        Please join us for a celebratory dinner following the
                        ceremony at Costal 59. A charcuterie spread will be
                        available during the cocktail hour. Jenna’s mom will
                        lead us in prayer to bless the food and marriage. Then,
                        we will enjoy salad, chicken, potatoes, and green beans
                        for dinner. Of course, there will be cake for dessert!
                      </span>{" "}
                      <br />
                      <br />
                      <span>
                        At the end of the dinner, we will share our first dance
                        as a married couple. Chris and his mom will have a
                        mother/son dance, then Jenna and her dad will have a
                        father/daughter dance. Finally, we will have our last
                        dance to close the formal part of the evening.
                      </span>
                      <br />
                      <br />
                      <span>
                        During this time, there will be an open bar with select
                        liquors, mixers, and beers.
                      </span>
                      <br />
                      <br />
                    </div>{" "}
                  </div>
                  <br />
                  <div className="topTimeline">
                    {" "}
                    <div className="secondTimeline">
                      <h3 className="cursiveFont">
                        6:30pm - 7:30pm – Sunset Photos (optional)
                      </h3>

                      <span>
                        Following the dinner, the bride and groom will go back
                        to the ocean side for sunset pictures. While the photos
                        will be of just the two of them, anyone is welcome to
                        spectate. There may be a few minute delay to get started
                        as they both will be changing so they can jump in the
                        water at the end of the session!
                      </span>
                      <br />
                      <br />
                      <span>
                        This is completely optional so please do not feel
                        obligated to attend.
                      </span>
                      <br />
                      <br />
                    </div>{" "}
                  </div>
                  <br />
                  <div className="topTimeline">
                    {" "}
                    <div className="secondTimeline">
                      <h3 className="cursiveFont">
                        8:30pm - After Party (optional)
                      </h3>

                      <span>
                        After the sunset pictures, take some time to relax, then
                        let’s reconvene to continue the party! We will need time
                        to change out of our “water” clothes/warm up, so please
                        feel free to do whatever you’d like during this time. We
                        will meet up at a bar around 8:30pm for an hour or two,
                        then go back to the AirBNB.
                      </span>

                      <br />
                      <br />
                    </div>
                  </div>
                  <br />
                  <div className="topTimeline">
                    {" "}
                    <div className="secondTimeline">
                      <h3 className="cursiveFont boldThedays">
                        Sunday, May 5th, 2024{" "}
                        <sup style={{ color: "rgba(11, 11, 110)" }}> ✸</sup>
                      </h3>
                      <h4 className="cursiveFont">10am - Brunch (optional)</h4>
                      <span>
                        As a thank-you for joining us on our special day, we
                        invite you to join us for a celebratory brunch at 10am.
                        We will be meeting at Denny’s close to 62nd Street.
                      </span>

                      <br />
                      <br />
                    </div>{" "}
                  </div>
                  <br />
                  <div className="topTimeline">
                    {" "}
                    <div className="secondTimeline">
                      <h3 className="cursiveFont">Timeline Locations</h3>
                      <span className="strongLocation">Ceremony Location</span>
                      <br />
                      <span>
                        59th Street Beach, Ocean City, MD 21842 (Oceanside)
                      </span>
                      <br />
                      <br />
                      <span className="strongLocation">Dinner Location</span>
                      <br />
                      <span>Coastal Dining Room (1st floor)</span>
                      <br />
                      <span>
                        5909 Coastal Highway, Ocean City, MD 21842 (Bayside)
                      </span>
                      <br />
                      <br />
                      <span className="strongLocation">
                        Ceremony Inclement Weather Location
                      </span>
                      <br />
                      <span>Indoor Space at Coastal 59</span>
                      <br />
                      <span>
                        5909 Coastal Highway, Ocean City, MD 21842 (Bayside)
                      </span>
                      <br />
                      <br />
                      <span className="strongLocation">
                        After Party Bar Location
                        <sup style={{ color: "rgb(218, 152, 156)" }}>
                          {" "}
                          ✸
                        </sup>{" "}
                      </span>
                      <br />
                      <span>
                        Skye Bar{" "}
                        <sup style={{ color: "rgb(218, 152, 156)" }}> ✸</sup>{" "}
                        <br />
                        6601 Coastal Hwy Ocean City, MD 21842
                        <br />
                        <br />
                        Seacrets <i>(possible)</i>{" "}
                        <sup style={{ color: "rgb(218, 152, 156)" }}> ✸</sup>{" "}
                        <br />
                        117 49th St Ocean City, MD 21842
                      </span>
                      <br /> <br />
                      We will meet up at Skye Bar on 67th around 8:30pm. After
                      an hour or two, we will either go to the AirBNB or
                      Seacrets then the AirBNB (we will decide as a group).{" "}
                      <sup style={{ color: "rgb(218, 152, 156)" }}> ✸</sup>{" "}
                      <br />
                      <br />
                      <span>
                        AirBNB information will be texted the weekend of the
                        wedding.
                      </span>
                      <br />
                      <br />
                      <span className="strongLocation">
                        Brunch Location
                      </span>{" "}
                      <br />
                      <span>Denny's</span>
                      <br />
                      <span>
                        6104 Coastal Highway, Ocean City, MD 21842 (Oceanside)
                      </span>
                      <br />
                      {/* <span>TBD</span> */}
                      <br />
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div
                class="card-header"
                id="headingThree"
                style={{ boxShadow: "0 4px 2px -2px gray" }}
              >
                <h5
                  class="mb-0"
                  id="collapseThree"
                  onClick={(e) => this.handleAccordion(e)}
                >
                  <button
                    class="btn btn-link collapsed cursiveFont largeFontButton"
                    data-toggle="collapse"
                    data-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                    onClick={(e) => this.handleAccordion(e)}
                    id="collapseThree"
                  >
                    Venue
                  </button>{" "}
                </h5>
              </div>
              <div
                class={
                  this.state.showAccordian === "collapseThree"
                    ? "collapse show"
                    : "collapse"
                }
                aria-labelledby="headingThree"
                data-parent="#accordion"
              >
                <div class="card-body straightFont">
                  <h3 className="cursiveFont text-center"> Ceremony & Venue</h3>
                  <div className=" text-center">
                    <p>
                      {" "}
                      We are working closely with Barefoot Bride to plan all the
                      details of our special day.
                    </p>
                    <p>
                      {" "}
                      The ceremony will occur on the oceanside of 59th street.
                    </p>
                    <p>
                      {" "}
                      After, we will cross the street to bayside and enjoy
                      dinner at the venue, Coastal 59.
                    </p>
                  </div>
                  <br />
                  <strong>Parking Option 1: </strong>
                  For the Ceremony: Park on 59th Street. The beach
                  entrance/dunes walk is handicap accessible and offers free
                  public parking. There are up to 38 free spaces and 2 handicap
                  spots available on a first-come, first-served basis.
                  <br />
                  <br />
                  <strong>Parking Option 2: </strong>
                  Park at Barefoot Bride/Coastal 59 Venue parking lot: You and
                  your guests are welcome to park in our parking lot, located on
                  the bayside of Coastal Hwy. between 59th and 60th Street. The
                  address is 5909 Coastal Hwy., Ocean City, MD 21842. <br />
                  <br />
                  When considering disabled guests, they can be dropped off on
                  the beach side, and drivers can park in the Barefoot/Coastal
                  59 Venue parking lot on the bayside. Access to the beach is
                  available by using the traffic light and crosswalk.
                  <br />
                  <br />
                  <strong style={{ color: "red" }}>IMPORTANT:</strong> Do not
                  park in the apartment complex lot, as vehicles will be towed.
                  Check the diagram below to find the designated parking areas
                  for you and your guests.
                </div>
                <div className="row justify-content-center ">
                  {this.state.width > 480 ? (
                    <img
                      src={require("./imageGallery/labelMap.JPG")}
                      className="mainPhoto"
                      style={{
                        height: "500px",
                        marginTop: "50px",
                        boxShadow: "0 4px 2px -2px gray",
                      }}
                    />
                  ) : (
                    <img
                      src={require("./imageGallery/labelMap.JPG")}
                      className="mainPhoto"
                      style={{
                        height: "300px",
                        width: "calc(100vw - 40px)",
                        marginTop: "50px",
                        boxShadow: "0 4px 2px -2px gray",
                      }}
                    />
                  )}
                </div>
                {/* <br />
                <br />

                <div class="card-body straightFont">
                  <h3 className="cursiveFont text-center">
                    {" "}
                    AirBNB <sup style={{ color: "rgba(11, 11, 110)" }}>
                      {" "}
                      ✸
                    </sup>{" "}
                  </h3>
                  <div className=" text-center">
                    <p>
                      {" "}
                      Outside of the venue, we plan for the AirBNB to be a fun
                      and central spot for many of the activities for the
                      weekend! We’ve included some pictures below so you have
                      any idea of what to expect. It’s a penthouse condo with
                      some amazing views! We will text out the address the
                      weekend of the wedding, but it is on 60th street (across
                      from the venue, on oceanside).
                    </p>
                    <p>
                      {" "}
                      After the rehearsal on Friday, everyone is invited over to
                      have pizza and hang out!
                    </p>
                    <p>
                      {" "}
                      On Saturday, anyone is welcome to hang out at any point
                      before the ceremony. It is large enough that both Jenna
                      and Chris plan to get ready there. 
                    </p>
                    <br />
                    Jenna will be getting her and make up done in the master
                    suite. Anyone is welcome to bring their hair and make up
                    stuff, along with their dresses, if they’d also like to get
                    ready in that room. Or, if you’d prefer, you can hang out
                    for a bit, leave and get ready in your own space, and come
                    back. Or any combination of the above! The intention is for
                    it to be low key and upbeat, so do whatever makes you happy
                    and comfortable.
                    <br />
                    <br />
                    Since gentlemen take significantly less time to get ready,
                    Chris plans to hang out/go with the flow/puts around the
                    town until ~3pm, then he will use one of the spare rooms to
                    get ready.
                    <br />
                    <br />
                    Finally, after we hang out at the bars for a bit, we’ll
                    continue the after party here.{" "}
                  </div>
                </div> */}

                <br />
                <br />
              </div>
            </div>

            <div class="card">
              <div
                class="card-header"
                id="headingThree"
                style={{ boxShadow: "0 4px 2px -2px gray" }}
              >
                <h5
                  class="mb-0"
                  id="collapseAirBNB"
                  onClick={(e) => this.handleAccordion(e)}
                >
                  <button
                    class="btn btn-link collapsed cursiveFont largeFontButton"
                    data-toggle="collapse"
                    data-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                    onClick={(e) => this.handleAccordion(e)}
                    id="collapseAirBNB"
                  >
                    AirBNB
                  </button>{" "}
                  <sup style={{ color: "rgba(11, 11, 110)" }}> ✸</sup>
                </h5>
              </div>
              <div
                class={
                  this.state.showAccordian === "collapseAirBNB"
                    ? "collapse show"
                    : "collapse"
                }
                aria-labelledby="headingThree"
                data-parent="#accordion"
              >
                <div class="card-body straightFont">
                  <h3 className="cursiveFont text-center">
                    AirBNB <sup style={{ color: "rgba(11, 11, 110)" }}> ✸</sup>
                  </h3>

                  <div class="card-body straightFont">
                    <h3 className="cursiveFont text-center"> </h3>
                    <div className=" text-center">
                      <p>
                        {" "}
                        Outside of the venue, we plan for the AirBNB to be a fun
                        and central spot for many of the activities for the
                        weekend! We’ve included some pictures below so you have
                        any idea of what to expect. It’s a penthouse condo with
                        some amazing views! We will text out the address the
                        weekend of the wedding, but it is on 60th street (across
                        from the venue, on oceanside).
                      </p>
                      <p>
                        {" "}
                        After the rehearsal on Friday, everyone is invited over
                        to have pizza and hang out!
                      </p>
                      <p>
                        {" "}
                        On Saturday, anyone is welcome to hang out at any point
                        before the ceremony. It is large enough that both Jenna
                        and Chris plan to get ready there. 
                      </p>
                      <br />
                      Jenna will be getting her hair and make up done in the
                      master suite. Anyone is welcome to bring their hair and
                      make up stuff, along with their dresses, if they’d also
                      like to get ready in that room. Or, if you’d prefer, you
                      can hang out for a bit, leave and get ready in your own
                      space, and come back. Or any combination of the above! The
                      intention is for it to be low key and upbeat, so do
                      whatever makes you happy and comfortable.
                      <br />
                      <br />
                      Since gentlemen take significantly less time to get ready,
                      Chris plans to hang out/go with the flow/puts around the
                      town until ~3pm, then he will use one of the spare rooms
                      to get ready.
                      <br />
                      <br />
                      Finally, after we hang out at the bars for a bit, we’ll
                      continue the after party here. <br />
                      <br />
                      <Carousel responsive={this.state.responsive}>
                        <div className="">
                          <img
                            src={require("./air1.jpg")}
                            width="200px"
                            height="300px"
                            class
                            className="1"
                          />{" "}
                        </div>
                        <div className="">
                          <img
                            src={require("./air2.jpg")}
                            width="200px"
                            height="300px"
                            class
                            className="1"
                          />{" "}
                        </div>
                        <div className="">
                          <img
                            src={require("./air3.jpg")}
                            width="200px"
                            height="300px"
                            class
                            className="1"
                          />{" "}
                        </div>
                        <div className="">
                          <img
                            src={require("./air4.jpg")}
                            width="200px"
                            height="300px"
                            class
                            className="1"
                          />
                        </div>
                        <div className="">
                          <img
                            src={require("./air5.jpg")}
                            width="200px"
                            height="300px"
                            class
                            className="1"
                          />
                        </div>
                        <div className="">
                          <img
                            src={require("./air6.jpg")}
                            width="200px"
                            height="300px"
                            class
                            className="1"
                          />
                        </div>
                        <div className="">
                          <img
                            src={require("./air7.jpg")}
                            width="200px"
                            height="300px"
                            class
                            className="1"
                          />
                        </div>
                        <div className="">
                          <img
                            src={require("./air8.jpg")}
                            width="200px"
                            height="300px"
                            class
                            className="1"
                          />
                        </div>
                        <div className="">
                          <img
                            src={require("./air9.jpg")}
                            width="200px"
                            height="300px"
                            class
                            className="1"
                          />
                        </div>
                      </Carousel>
                    </div>
                  </div>
                </div>

                <br />
                <br />

                <br />
                <br />
              </div>
            </div>

            <div class="card">
              <div
                class="card-header"
                id="headingOne"
                style={{ boxShadow: "0 4px 2px -2px gray" }}
              >
                <h5
                  class="mb-0"
                  id="collapseFour"
                  onClick={(e) => this.handleAccordion(e)}
                >
                  <button
                    class="btn btn-link cursiveFont largeFontButton"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                    id="collapseFour"
                    onClick={(e) => this.handleAccordion(e)}
                  >
                    Guest Details
                  </button>
                </h5>
              </div>

              <div
                class={
                  this.state.showAccordian === "collapseFour"
                    ? "collapse show"
                    : "collapse"
                }
                aria-labelledby="headingOne"
                data-parent="#accordion"
              >
                <div class="card-body">
                  <span className="straightFont">
                    Thank you for joining us on our special day! We will not be
                    having a “formal” bridal party. However, we do ask that you
                    wear one of our wedding colors – navy blue, mauve, dusty
                    rose, sage green, gray, or black. <br />
                  </span>

                  <br />
                  <div className="row justify-content-center ">
                    <div>
                      <span
                        class="dot"
                        style={{ backgroundColor: "rgba(0, 33, 115)" }}
                      ></span>
                      <span
                        class="dot"
                        style={{ backgroundColor: "rgba(218, 152, 156)" }}
                      ></span>
                      <span
                        class="dot"
                        style={{ backgroundColor: "rgba(255, 198, 197)" }}
                      ></span>

                      <span
                        class="dot"
                        style={{ backgroundColor: "rgba(85, 133, 96)" }}
                      ></span>
                      <span
                        class="dot"
                        style={{ backgroundColor: "rgba(106, 106, 106)" }}
                      ></span>
                      <span
                        class="dot"
                        style={{ backgroundColor: "rgba(0, 0, 0)" }}
                      ></span>
                    </div>
                  </div>

                  <br />

                  <span className="straightFont">
                    Our overall mantra for the celebration is comfortable and
                    classy, and we’d love to see that reflected in your attire
                    as well. Keep in mind that it is a spring wedding on the
                    beach, so dress how you feel is the most appropriate. If you
                    have any questions or would like any opinions, please feel
                    free to ask Jenna. <br />
                    <br />
                    <div className="text-center">
                      {this.state.width > 480 ? (
                        <img
                          src={require("./colors1.JPG")}
                          // width="30px"
                          // height="30px"
                          class
                          Name=""
                        />
                      ) : (
                        <Carousel responsive={this.state.responsive}>
                          <div className="">
                            <img
                              src={require("./imageGallery/stock/collection1.JPG")}
                              width="200px"
                              height="300px"
                              class
                              className="1"
                            />{" "}
                          </div>
                          <div className="">
                            <img
                              src={require("./imageGallery/stock/collection2.JPG")}
                              width="200px"
                              height="300px"
                              class
                              className="1"
                            />{" "}
                          </div>
                          <div className="">
                            <img
                              src={require("./imageGallery/stock/collection3.JPG")}
                              width="200px"
                              height="300px"
                              class
                              className="1"
                            />{" "}
                          </div>
                          <div className="">
                            <img
                              src={require("./imageGallery/stock/collection4.JPG")}
                              width="200px"
                              height="300px"
                              class
                              className="1"
                            />
                          </div>
                        </Carousel>
                      )}
                    </div>
                    <br />
                    Our male guests do not have to wear a suit or a tuxedo
                    unless they wish; a button down, tie, and nice slacks would
                    be perfect. Similarly, our female guests do not have to wear
                    the equivalent of a bridesmaids gown. Below is some
                    inspiration based on our vision:
                    <br /> <br />
                    <div className="text-center">
                      {this.state.width > 480 ? (
                        <img
                          src={require("./colors2.JPG")}
                          // width="30px"
                          // height="30px"

                          className="2"
                          style={{ maxWidth: "480px" }}
                        />
                      ) : (
                        <div>
                          <Carousel responsive={this.state.responsive}>
                            <div className="">
                              <img
                                src={require("./imageGallery/stock/2.JPG")}
                                width="150px"
                                height="200px"
                                className="2"
                              />{" "}
                            </div>
                            <div className="">
                              <img
                                src={require("./imageGallery/stock/1.JPG")}
                                width="150px"
                                height="200px"
                                className="2"
                              />
                            </div>
                            <div className="">
                              <img
                                src={require("./imageGallery/stock/3.JPG")}
                                width="150px"
                                height="200px"
                                className="2"
                              />
                            </div>
                            <div className="">
                              <img
                                src={require("./imageGallery/stock/4.JPG")}
                                width="150px"
                                height="200px"
                                class
                                className="2"
                              />
                            </div>
                            <div className="">
                              <img
                                src={require("./imageGallery/stock/5.JPG")}
                                width="150px"
                                height="200px"
                                class
                                className="2"
                              />
                            </div>
                            <div className="">
                              <img
                                src={require("./imageGallery/stock/6.JPG")}
                                width="150px"
                                height="200px"
                                class
                                className="2"
                              />
                            </div>
                            <div className="">
                              <img
                                src={require("./imageGallery/stock/7.JPG")}
                                width="150px"
                                height="200px"
                                class
                                className="2"
                              />
                            </div>
                            <div className="">
                              <img
                                src={require("./imageGallery/stock/8.JPG")}
                                width="150px"
                                height="200px"
                                class
                                className="2"
                              />
                            </div>
                            <div className="">
                              <img
                                src={require("./imageGallery/stock/9.JPG")}
                                width="150px"
                                height="200px"
                                class
                                className="2"
                              />
                            </div>
                            <div className="">
                              <img
                                src={require("./imageGallery/stock/10.JPG")}
                                width="150px"
                                height="200px"
                                class
                                className="2"
                              />{" "}
                            </div>
                            <div className="">
                              <img
                                src={require("./imageGallery/stock/11.JPG")}
                                width="150px"
                                height="200px"
                                class
                                className="2"
                              />{" "}
                            </div>
                            <div className="">
                              <img
                                src={require("./imageGallery/stock/12.JPG")}
                                width="150px"
                                height="200px"
                                class
                                className="2"
                              />{" "}
                            </div>
                            <div className="">
                              <img
                                src={require("./imageGallery/stock/13.JPG")}
                                width="150px"
                                height="200px"
                                class
                                className="2"
                              />{" "}
                            </div>
                            <div className="">
                              <img
                                src={require("./imageGallery/stock/14.JPG")}
                                width="150px"
                                height="200px"
                                class
                                className="2"
                              />{" "}
                            </div>
                            <div className="">
                              <img
                                src={require("./imageGallery/stock/15.JPG")}
                                width="150px"
                                height="200px"
                                class
                                className="2"
                              />{" "}
                            </div>
                            <div className="">
                              <img
                                src={require("./imageGallery/stock/16.JPG")}
                                width="150px"
                                height="200px"
                                class
                                className="2"
                              />{" "}
                            </div>
                            <div className="">
                              <img
                                src={require("./imageGallery/stock/17.JPG")}
                                width="150px"
                                height="200px"
                                class
                                className="2"
                              />{" "}
                            </div>
                          </Carousel>
                        </div>
                      )}
                    </div>
                    <br />
                    To avoid blending in with the surroundings, we kindly
                    request that you do NOT wear very light colored clothing,
                    such as a light tan/light khaki. As you can see in the
                    following photos, we find that these colors do not
                    photograph well on the beach.
                    <br />
                    <br />
                    <div className="text-center">
                      {this.state.width > 480 ? (
                        <img
                          src={require("./colors3.JPG")}
                          // width="30px"
                          // height="30px"
                          class
                          Name=""
                        />
                      ) : (
                        <Carousel responsive={this.state.responsive}>
                          <div>
                            <img
                              src={require("./smallcolors3.JPG")}
                              // width="30px"
                              // height="30px"
                              class
                              Name=""
                              style={{ maxWidth: "480px" }}
                            />{" "}
                          </div>
                          <div>
                            <img
                              src={require("./imageGallery/stock/22.JPG")}
                              // width="150px"
                              // height="200px"
                              class
                              Name=""
                              style={{ maxWidth: "480px" }}
                            />{" "}
                          </div>
                          <div>
                            <img
                              src={require("./imageGallery/stock/23.JPG")}
                              // width="150px"
                              // height="200px"
                              class
                              Name=""
                              style={{ maxWidth: "480px" }}
                            />
                          </div>
                        </Carousel>
                      )}
                    </div>
                  </span>
                </div>
              </div>
            </div>

            <div class="card">
              <div
                class="card-header"
                id="headingOne"
                style={{ boxShadow: "0 4px 2px -2px gray" }}
              >
                <h5
                  class="mb-0"
                  id="collapseFive"
                  onClick={(e) => this.handleAccordion(e)}
                >
                  <button
                    class="btn btn-link cursiveFont largeFontButton"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                    id="collapseFive"
                    onClick={(e) => this.handleAccordion(e)}
                  >
                    Hotel Accommodations
                  </button>
                </h5>
              </div>

              <div
                class={
                  this.state.showAccordian === "collapseFive"
                    ? "collapse show"
                    : "collapse"
                }
                aria-labelledby="headingOne"
                data-parent="#accordion"
              >
                <div class="card-body straightFont">
                  We recognize that everyone’s family and financial situations
                  are very different. For that reason, we have decided not to
                  reserve a block of rooms at a particular hotel. There are
                  numerous hotels and condos available in all of Ocean City,
                  especially within a few minutes of 59th street. Please choose
                  what you feel is best for you! <br />
                  <br />
                  If you choose to book an AirBNB, we highly recommend avoiding
                  Vacasa Rentals – they are a third party company that condo
                  owners rent through and are known for being{" "}
                  <strong>
                    <u>unreliable.</u>
                  </strong>
                  <br />
                  <br />
                  We highly recommend that you research every hotel/condo that
                  you are interested in (especially looking at recent reviews).
                  If you would like help, please let Jenna or Chris know. Our
                  wedding planner has provided the following insight and
                  recommendations.
                  <br />
                  <br />
                  They have learned from other couples’ feedback that online
                  booking services such as hotels.com, booking.com, and
                  priceline.com were less expensive, but also more difficult to
                  cancel if needed.
                  <br />
                  <br />
                  The following is within walking distance of the venue (but
                  please don’t feel obligated to stay at one of these by any
                  means):
                  <ul>
                    <li>Best Western/Sea Bay Hotel</li>
                    <li>The Edge and The Light House</li>
                    <li>Coconut Malorie Resort</li>
                    <li>Marriott Residence Inn</li>
                    <li>Sand Piper Dunes (Condo, Air BNB)</li>
                    <li>Adagio (Condo, Air BNB)</li>
                    <li>
                      They also suggest visiting
                      https://ococean.com/deals/accommodations
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="card">
              <div
                class="card-header"
                id="headingOne"
                style={{ boxShadow: "0 4px 2px -2px gray" }}
              >
                <h5
                  class="mb-0"
                  id="collapseSeven"
                  onClick={(e) => this.handleAccordion(e)}
                >
                  <button
                    class="btn btn-link cursiveFont largeFontButton"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                    id="collapseSeven"
                    onClick={(e) => this.handleAccordion(e)}
                  >
                    Things To Do{" "}
                    <sup style={{ color: "rgb(218, 152, 156)" }}> ✸</sup>
                  </button>
                </h5>
              </div>

              <div
                class={
                  this.state.showAccordian === "collapseSeven"
                    ? "collapse show"
                    : "collapse"
                }
                aria-labelledby="headingOne"
                data-parent="#accordion"
              >
                <div class="card-body straightFont">
                  <p>
                    Since we will be getting married in the “off-season,” the
                    ocean is likely going to be too cold to swim in. However,
                    you can absolutely take walks at sunrise, sunset, or any
                    other time of the day (all of which are gorgeous)! There are
                    many other entertainment options to explore while you are
                    visiting the area, some of which we included below. A simple
                    Google search for “things to do in Ocean City Maryland” will
                    also give you a plethora of ideas!
                    <br />
                    <br />
                    <strong className="cursiveFont">
                      <h4>
                        Transportation{" "}
                        <sup style={{ color: "rgb(218, 152, 156)" }}> ✸</sup>{" "}
                      </h4>
                    </strong>{" "}
                    A convenient way to get around Ocean City is through the
                    Ocean City Beach Bus. These buses continually run up and
                    down Coastal Highway and stop every few blocks. Information
                    about fare, bus schedules, and more can be found on their
                    <a href="  https://oceancitymd.gov/oc/departments/public-works/ocean-city-beach-bus/">
                      {" "}
                      website.
                    </a>
                    <br />
                    <br />
                    <span>
                      When we visited at the end of March, the fare was $4 per
                      day. Exact cash is required as you deposit the money into
                      a box, then the bus driver will hand you a ticket. You
                      will need to show this ticket each time you board
                      throughout the day.{" "}
                      <sup style={{ color: "rgb(218, 152, 156)" }}> ✸</sup>
                      <br />
                      <br />
                      The bus runs every 30 minutes. There are stops every block
                      or two on both sides of coastal highway. We recommend
                      downloading the app to see the buses in real time.{" "}
                      <sup style={{ color: "rgb(218, 152, 156)" }}> ✸</sup>
                    </span>
                    <br />
                    <br />
                    <strong className="cursiveFont">
                      <h4> Boardwalk </h4>
                    </strong>{" "}
                    The Ocean City Boardwalk is packed with family friendly
                    activities, restaurants, shops, and a whole lot of fun! It’s
                    almost 3 miles long and has received numerous awards and
                    recognitions. The boardwalk is also home to{" "}
                    <a href="https://www.ripleys.com/oceancity/">
                      Ripley’s Believe It or Not!{" "}
                    </a>{" "}
                    Although we will be in the “off-season,” many of the{" "}
                    <a href="https://www.ococean.com/things-to-do/attractions/boardwalk/">
                      attractions
                    </a>{" "}
                    should still be available.
                    <br />
                    <br />
                    <strong className="cursiveFont">
                      <h4> Arts & Culture </h4>
                    </strong>{" "}
                    There are many{" "}
                    <a href="  https://www.ococean.com/things-to-do/arts-culture/">
                      museums, festivals, and other entertainment opportunities{" "}
                    </a>{" "}
                    that occur all year round.
                    <br />
                    <br />
                    <strong className="cursiveFont">
                      {" "}
                      <h4>Mini Golf </h4>{" "}
                    </strong>
                    You’re never too far away from a mini golf spot!{" "}
                    <a href="    https://nicksminigolf.com/">
                      Nick’s Mini Golf{" "}
                    </a>{" "}
                    is only a few blocks away from the venue, so likely close to
                    your hotel or condo as well.
                    <br />
                    <br />
                    <strong className="cursiveFont">
                      {" "}
                      <h4>Shopping </h4>{" "}
                    </strong>
                    There are countless strip malls, mom and pop shops, and
                    chain stores to get your shopping fill in! Approximately
                    every 5 feet (more or less) is a Sunsations, which is a very
                    popular place for tourists to get OCMD souvenirs. Luckily,
                    there is{" "}
                    <a href="  https://sunsationsusa.com/ ">
                      one 2 blocks from the venue!{" "}
                    </a>
                    <br />
                    <br />
                    <strong className="cursiveFont">
                      {" "}
                      <h4>Seacrets </h4>
                    </strong>
                    <a href="  https://seacrets.com/ ">Seacrets</a> is one of
                    the most popular venues for adults to hang out! There are
                    numerous different bars, night clubs, and dining options
                    available.
                  </p>
                </div>
              </div>
            </div>

            <div class="card">
              <div
                class="card-header"
                id="headingOne"
                style={{ boxShadow: "0 4px 2px -2px gray" }}
              >
                <h5
                  class="mb-0"
                  id="collapseSix"
                  onClick={(e) => this.handleAccordion(e)}
                >
                  <button
                    class="btn btn-link cursiveFont largeFontButton"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                    id="collapseSix"
                    onClick={(e) => this.handleAccordion(e)}
                  >
                    Registry
                  </button>
                </h5>
              </div>

              <div
                class={
                  this.state.showAccordian === "collapseSix"
                    ? "collapse show"
                    : "collapse"
                }
                aria-labelledby="headingOne"
                data-parent="#accordion"
              >
                <div class="card-body straightFont">
                  <u>
                    As cliché as it may sound, your presence is the best present
                    that you could ever gift!
                  </u>{" "}
                  <i>(For realz) </i>
                  <br />
                  <br />
                  We kindly ask that instead of a gift, you prioritize travel
                  and accommodations to join us on our special weekend. <br />
                  <br />
                  Thank you in advance for your understanding and respecting our
                  decision.
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer class="footer">
          <div class="container">
            <span class="text-muted cursiveFont bolding hashTag">
              #OnceUponAMilheim
            </span>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default Celebration;
